import { ReactNode } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid2 as Grid, Typography } from '@mui/material';
import { createElementId } from '@constants/id';
import { theme } from '@util/theme';

interface Props {
	id: string;
	title: string;
	summary: string;
	complete: boolean;
	expanded: boolean;
	children: ReactNode;
}

export function FormSection({ id, title, summary, complete, expanded, children }: Props) {
	const ariaControls = `${id}-content`;
	return (
		<Accordion disableGutters defaultExpanded={expanded}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={ariaControls}
				id={id}
				sx={{ backgroundColor: theme.palette.grey[100] }}
			>
				<Grid container direction="row" size={12}>
					<Grid size={1}>
						{complete ? (
							<CheckCircleIcon sx={{ color: 'green' }} />
						) : (
							<CheckCircleOutlineIcon sx={{ color: 'text.secondary' }} />
						)}
					</Grid>
					<Grid size={2}>
						<Typography
							id={createElementId(['form_section_title', id])}
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							{title}
						</Typography>
					</Grid>
					<Grid size={9}>
						<Typography
							id={createElementId(['form_section_summary', id])}
							variant="subtitle1"
							sx={{ color: 'text.secondary' }}
						>
							{summary}
						</Typography>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
