import { Typography } from '@mui/material';
import {
	calculateActualWeight,
	calculatedVolumetricWeight,
	formatNumberTwoDecimals
} from '@pages/Shipments/utils/utils';
import { Shipment } from '@tunl-types/shipment';

export interface ParcelDetailsProps {
	shipment: Shipment;
}

export default function ParcelDetails(props: ParcelDetailsProps) {
	const { shipment } = props;
	const newShipmentView = shipment.parcels && shipment.parcels.length > 0;

	return <>{newShipmentView ? multiParcelDetailsDisplay(shipment) : singleParcelDetailsDisplay(shipment)}</>;
}

const singleParcelDetailsDisplay = (shipment: Shipment) => {
	return (
		<>
			<Typography variant="body1">
				Dimensions: {shipment.parcel_length_cm}x{shipment.parcel_height_cm}x{shipment.parcel_width_cm} cm
			</Typography>
			<Typography variant="body1">Actual Weight: {shipment.parcel_weight_kg} kg</Typography>
			<Typography variant="body1">Volumetric Weight: {shipment.parcel_weight_vol_kg} kg</Typography>
		</>
	);
};

const multiParcelDetailsDisplay = (shipment: Shipment) => {
	return (
		<>
			<Typography variant="body1">Total Parcels: {shipment?.parcels?.length}</Typography>
			<Typography variant="body1">Total Actual Weight: {calculateActualWeight(shipment?.parcels)} kg</Typography>
			<Typography variant="body1">
				Volumetric Weight: {formatNumberTwoDecimals(calculatedVolumetricWeight(shipment?.parcels ?? []) ?? 0)}{' '}
				kg
			</Typography>
		</>
	);
};
