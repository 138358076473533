import Grid from '@mui/material/Grid2';
import PickupDetails from './PickupDetails';
import AddressDetails from './AddressDetails';
import { MultiPieceShipment } from '@tunl-types/shipment';

interface props {
	shipment: MultiPieceShipment;
}

export default function AddressContainer({ shipment }: props) {
	return (
		<Grid container direction={'row'} size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} spacing={1}>
			<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
				<AddressDetails shipment={shipment} type="From" />
			</Grid>
			<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
				<AddressDetails shipment={shipment} type="To" />
			</Grid>
			<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
				<PickupDetails shipment={shipment} />
			</Grid>
		</Grid>
	);
}
