import { axiosConfig } from '@constants/axios';

export const createUserProfile = async (user: any) => {
	const axiosInstance = axiosConfig();
	try {
		const userCreateResponse = await axiosInstance.post('/user/user', {
			user_email: user.attributes.email,
			user_name: user.attributes.name,
			user_id: user.username,
			user_merchants: []
		});
		sessionStorage.setItem('user', JSON.stringify(userCreateResponse.data.data));

		return userCreateResponse.data.data;
	} catch (ex) {
		console.log(ex);
	}
};
