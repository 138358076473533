import { AppBar, Box, styled } from '@mui/material';
import { theme } from '@util/theme';

const StyledAppBar = styled(AppBar)({
	zIndex: theme.zIndex.drawer + 1,
	backgroundColor: theme.palette.primary.main,
	height: 60,
	position: 'fixed',
	boxShadow: 'none',
	justifyContent: 'center',
	marginBottom: '100px'
});

export default function TrackerHeader() {
	return (
		<StyledAppBar>
			<Box
				component="img"
				src="../img/tunl-logo-cream.png"
				alt="Tunl Logo"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					marginLeft: 'auto',
					maxWidth: '170px',
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}
			/>
		</StyledAppBar>
	);
}
