import React, { useState } from 'react';
import { axiosConfig } from '../../../constants/axios';
import { Alert, Backdrop, Button, CircularProgress, Grid2 as Grid, Snackbar } from '@mui/material';
import { Download } from '@mui/icons-material';

export interface DownloadDocumentsProps {
	shipmentId: string;
	fileName: string;
}

export const DownloadDocuments: React.FC<DownloadDocumentsProps> = ({ shipmentId, fileName }) => {
	const [downloading, setDownloading] = useState(false);
	const [downloadingError, setDownloadingError] = useState(false);
	const axiosInstance = axiosConfig();

	async function downloadAllFiles() {
		setDownloading(true);
		await axiosInstance
			.get(`/api/v1/shipments/${shipmentId}/documents`, {
				responseType: 'arraybuffer',
				headers: {
					Accept: 'application/pdf'
				}
			})
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `${fileName || 'labels'}.pdf`; // Default download file name
				link.click();

				link.remove();
				URL.revokeObjectURL(link.href);
			})
			.catch(e => {
				console.log(e);
				setDownloadingError(true);
			})
			.finally(() => {
				setDownloading(false);
			});
	}

	return (
		<>
			<Button
				variant="contained"
				size="small"
				onClick={() => {
					downloadAllFiles();
				}}
				sx={{ float: 'right' }}
			>
				<Download />
				Download Documents
			</Button>
			<Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={downloading}>
				<Grid container spacing={2}>
					<Grid size={12} display="flex" justifyContent="center" alignContent="center">
						<CircularProgress color="inherit" />
					</Grid>
				</Grid>
			</Backdrop>
			<Snackbar
				open={downloadingError}
				autoHideDuration={4000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={() => setDownloadingError(false)}
			>
				<Alert severity="error">Unable to process request</Alert>
			</Snackbar>
		</>
	);
};
