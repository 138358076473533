const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string, region: string | null = null) => {
	try {
		if (region) {
			phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone), region);
		}
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
	} catch (error) {
		return false;
	}
};
