import { Grid, Link, Typography } from '@mui/material';
import { Shipment } from '@tunl-types/shipment';

export interface CustomerDetailsProps {
	shipment: Shipment;
}

export default function CustomerDetails(props: CustomerDetailsProps) {
	const { shipment } = props;

	return (
		<>
			<Grid item>
				<Link href={`mailto:${shipment.address_to_email}`} color="inherit" underline="always">
					<Typography variant="body1">{shipment.address_to_email}</Typography>
				</Link>
			</Grid>
			<Grid item>
				<Typography variant="body1">{shipment.address_to_phone}</Typography>
			</Grid>
			{shipment.xero_invoice_number && (
				<Grid item>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
						TUNL Invoice Number:
					</Typography>
					<Typography variant="body1">{shipment.xero_invoice_number}</Typography>
				</Grid>
			)}
		</>
	);
}
