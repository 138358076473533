import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#007BC4' // Customize primary color
		},
		secondary: {
			main: '#ffffff' // Customize secondary color
		}
	},
	typography: {
		fontSize: 12,
		fontFamily: ['Nunito', 'sans-serif'].join(',')
	},
	components: {
		MuiSelect: {
			styleOverrides: {
				select: {
					fontSize: 12
				}
			}
		}
	}
});