import { Box, Grid, Typography } from '@mui/material';
import {
	MRT_RowSelectionState,
	MaterialReactTable,
	useMaterialReactTable,
	type MRT_ColumnDef //if using TypeScript (optional, but recommended)
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { calculateInsuredValue } from '../BulkShipments';
import { MockShipment } from '@tunl-types/mockShipments';

export default function BulkShipmentsTable(props: any) {
	const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

	const { mockShipmentListTemp, setSelectedMockShipmentListTemp } = props;

	const data: any = mockShipmentListTemp;
	const setAddressLongString = (rowItem: MockShipment) => {
		return [
			rowItem.address_to_street_1,
			rowItem.address_to_street_2,
			rowItem.address_to_city_locality,
			rowItem.address_to_state_province,
			rowItem.address_to_zip_postal,
			rowItem.address_to_country
		]
			.filter(Boolean) // Remove any falsy values like null, undefined, or empty strings
			.join(', ');
	};

	const formatParcelDimensions = (shipment: MockShipment) => {
		return `L: ${shipment.parcel_length_cm}cm,   W: ${shipment.parcel_width_cm}cm,   H: ${shipment.parcel_height_cm}cm,   W: ${shipment.parcel_weight_kg}kg`;
	};
	//keep track of rows that have been edited
	const [editedMockShipments, setEditedMockShipments] = useState<Record<string, any>>({});

	const columns = useMemo<MRT_ColumnDef<any>[]>(
		() => [
			{
				accessorFn: originalRow => originalRow.shipment_data.order_number,
				header: 'Order #',
				minSize: 100,
				maxSize: 150,
				size: 50,
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow => originalRow.shipment_data.date_created,
				header: 'Date',
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow => originalRow.shipment_data.address_to_name,
				header: 'Customer',
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow => setAddressLongString(originalRow.shipment_data),
				header: 'Shipping Address',
				minSize: 250,
				maxSize: 400,
				size: 350,
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow => formatParcelDimensions(originalRow.shipment_data),
				header: 'Package Dimensions',
				minSize: 200,
				maxSize: 350,
				size: 250,
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow => originalRow.shipment_data.service,
				header: 'Service',
				size: 250,
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			},
			{
				accessorFn: originalRow =>
					`R ${!!originalRow.shipment_data?.rate === true ? originalRow.totalCost : '0.00'}`,
				header: 'Total Cost',
				Cell: ({ cell }) => <p>{cell.getValue<string>()}</p>
			}
		],
		[]
	);

	const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

	//pass table options to useMaterialReactTable
	const table = useMaterialReactTable({
		enableDensityToggle: false,
		initialState: { density: 'compact' },
		columns,
		data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableRowSelection: row => {
			const rateReturned = row.original.shipment_data?.rate;
			return !!rateReturned;
		},
		onRowSelectionChange: setRowSelection,
		state: { rowSelection },
		muiSelectCheckboxProps: {
			color: 'primary' //makes all checkboxes use the secondary color
		},
		createDisplayMode: 'row',
		editDisplayMode: 'table',
		enableEditing: false,
		positionActionsColumn: 'last',
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor:
					row.original.shipment_data?.rate === undefined || row.original.shipment_data?.rate === null
						? '#f0f0f0'
						: 'white'
			}
		}),
		renderDetailPanel: ({ row }) => {
			const rateReturned = row.original.shipment_data?.rate;
			return (
				<Box>
					{rateReturned ? (
						<Grid container sx={{ border: '1px solid #ccc' }}>
							<Grid item md={12} sx={{ padding: '16px' }}>
								{displayCostBreakDown(row.original)}
							</Grid>
						</Grid>
					) : (
						<Grid container sx={{ border: '1px solid red' }}>
							<Grid item md={12} sx={{ padding: '16px' }}>
								<Typography>
									We're sorry, but we couldn't find any rates. Please ensure that the address fields
									are correct.
								</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			);
		}
	});

	useEffect(() => {
		setSelectedMockShipmentListTemp(data.filter((_: any, index: number) => rowSelection.hasOwnProperty(index)));
	}, [rowSelection, data]);

	return (
		<Box sx={{ mt: 2 }}>
			<MaterialReactTable table={table} />
		</Box>
	);

	function calcalateLandedCost(landedCost: any): number {
		let landedCostRate = 0;
		if (landedCost && landedCost.amountSubtotals) {
			const { amountSubtotals } = landedCost;
			let landedCostTotal = amountSubtotals.duties + amountSubtotals.fees + amountSubtotals.taxes;

			const { exchangeRate } = landedCost;
			if (exchangeRate) {
				if (exchangeRate.sourceCurrencyCode !== exchangeRate.targetCurrencyCode) {
					// Convert to number after applying toFixed(2)
					landedCostTotal = parseFloat((landedCostTotal * exchangeRate.rate).toFixed(2));
				}
			}
			landedCostRate = Number(landedCostTotal); // Ensure landedCostRate is a number
		}
		return landedCostRate;
	}

	function displayCostBreakDown(data: any) {
		return (
			<Grid container direction={'row'} alignContent="flex-start">
				<Grid item md={3}>
					<Typography variant="body1" align="left">
						{`Shipping Cost: R ${Number(data.shipment_data?.rate ?? 0).toFixed(2)}`}
					</Typography>
				</Grid>
				{(data.service_type === 'DAP' || data.shipment_data?.service !== 'Ground') && (
					<Grid item md={3}>
						<Typography variant="body1" align="left">
							{`Landed Cost: ${calcalateLandedCost(data.landed_cost).toFixed(2)}`}
						</Typography>
					</Grid>
				)}
				<Grid item md={3}>
					<Typography variant="body1" align="left">
						{`Shipment Protection: R ${calculateInsuredValue(data.shipment_data.insurance)}`}
					</Typography>
				</Grid>
				<Grid item md={3}>
					<Typography variant="body1" align="left">
						{`Signature Required: R ${(data.shipment_data.signature ? 50 : 0).toFixed(2)}`}
					</Typography>
				</Grid>
			</Grid>
		);
	}
}
