import { AddressData } from '@tunl-types/address';
import { Parcel } from '@tunl-types/shipment';
import { currencyToSymbolConversion } from '@util/currency';

export function formatAddressForSectionSummary(address: AddressData, canFormat: boolean = true) {
	if (canFormat) {
		return `${address.street1}, ${address.cityLocality}, ${address.stateProvince}, ${address.zipPostal}, ${address.country}`;
	}

	return '';
}

export function formatAmount(amount: number) {
	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	}).format(amount);
}

export function formatNumberTwoDecimals(amount: number): number {
	return parseFloat(amount.toFixed(2));
}

export function getCurrency(shipment: any) {
	let shipmentCurrency: string = '';
	if (shipment?.variant === 'MULTI') {
		shipmentCurrency = shipment?.parcels[0]?.customs_items?.[0]?.currency_code;
	} else if (shipment.variant === 'SINGLE') {
		shipmentCurrency = shipment?.customsItems?.customs_items[0]?.currency_code;
	} else {
		shipmentCurrency = 'ZAR';
	}
	return currencyToSymbolConversion(shipmentCurrency);
}

export function calculateCustomsVolumetricWeight(shipment: any) {
	return (shipment.parcelHeightCm * shipment.parcelLengthCm * shipment.parcelWidthCm) / 5000;
}

export function calculateActualWeight(parcels: Parcel[] | undefined) {
	return (parcels || [])
		.map(parcel => parcel.weight)
		.reduce((accumulator, currentValue) => accumulator + currentValue);
}

export const calculatedVolumetricWeight = (parcels: Array<Parcel>): number => {
	if (!parcels || parcels.length === 0) {
		return 0;
	}

	return parcels.reduce((sum, parcel) => sum + calculateParcelVolumetricWeight(parcel), 0);
};

export const calculateParcelVolumetricWeight = (parcel: Parcel) => {
	return (parcel.height * parcel.length * parcel.width) / 5000;
};
