import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	CircularProgress,
	Grid,
	Paper,
	Snackbar,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { useMerchant } from '@context/MerchantContext';
import { setAlertBoolean } from '@stores/alert';
interface AppProps {
	children: React.ReactNode;
	user: any;
	signOut: any;
}

export default function Layout({ children, user, signOut }: AppProps) {
	const { merchantProfile, userProfile } = useMerchant();
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const [open, setState] = useState(isXsSmMd ? true : false);

	const dispatch = useDispatch();
	const { pathname } = useLocation();

	if (pathname === '/shipment/details' || pathname === '/shipment/details') {
		dispatch(setAlertBoolean('false'));
	}

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event &&
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setState(open);
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Header
				open={open}
				signOut={signOut}
				user={JSON.parse(sessionStorage.getItem('user') as string)}
				isXsSmMd={isXsSmMd}
				toggleDrawer={toggleDrawer}
				showNavigation={merchantProfile?._id ? true : false}
			/>

			<Box component="main" sx={{ flexGrow: 1, pb: merchantProfile._id ? '60px' : 0 }} pt="60px">
				<Grid container>
					<Grid item xs={12}>
						{children}
					</Grid>
				</Grid>
			</Box>

			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
					{apiResponse.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}
