import { axiosConfig } from '@constants/axios';
import {
	AddCircleOutline,
	CopyAll,
	EditRounded,
	KeyboardArrowDown,
	KeyboardArrowUp,
	RemoveCircleOutline
} from '@mui/icons-material';
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Modal,
	Paper,
	Popover,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import { BigBoxRecord } from '@tunl-types/bigbox';
import { Booking } from '@tunl-types/booking';
import { SnackAlert } from '@tunl-types/util';
import axios from 'axios';
import React, { Fragment, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

function ReviewRow(props: any) {
	const {
		booking,
		bookings,
		handleAddBoxModal,
		handleEditModal,
		bookingIndex,
		setBookings,
		setAssignModalLoading,
		setEditModalLoading,
		setUnassignedBigBoxes
	} = props;
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const axiosInstance = axiosConfig();

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const handleLeaveOpen = () => {
		setModalOpen(true);
	};

	const handleLeaveClose = () => setModalOpen(false);

	const getShipmentsByBookingId = async (bookingId: string) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/shipment/getShipmentsBookingID', {
				booking_id: bookingId
			});
			if (response.data.data && response.data.data.length > 0) {
				navigate('/customs/shipments/review', {
					state: response.data.data
				});
			}
			setSnackAlert({ type: 'success', message: response.data.message });
			setOpenSnackBar(true);
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
	};

	const openManualTrackers = (bookingId: string) => {
		navigate('/customs/push_trackers', {
			state: bookingId
		});
	};

	async function getBigBoxData(bookingId: string) {
		try {
			const response = await axiosInstance.post('/bigbox/getBigBoxByBookingID', {
				booking_id: bookingId
			});
			return response.data.data as Booking[];
		} catch (err) {
			console.log(err);
			return {} as Booking[];
		} // eslint-disable-line react-hooks/exhaustive-deps
	}

	const generateWorldContainerReport = async (bookingId: string) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/customs/generateWorldContainer', {
				booking_id: bookingId
			});
			setSnackAlert({ type: 'success', message: response.data.message });
			setOpenSnackBar(true);
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
	};

	const clearShipmentsToEasyClear = async (bookingId: string) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/easyclear/clearBookingToEasyClear', {
				booking_id: bookingId
			});
			response.data.message === 'Success'
				? setSnackAlert({ type: 'success', message: response.data.message })
				: response.data.message === 'Error'
				? setSnackAlert({ type: 'error', message: response.data.error })
				: setSnackAlert({ type: 'warning', message: response.data.message });
			setOpenSnackBar(true);
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
	};

	const onClickAddBigBoxButton = async (booking: Booking) => {
		setAssignModalLoading(true);
		await handleAddBoxModal(bookingIndex);
		const updatedBookings = [...bookings];
		const bigBoxes = await getBigBoxData(booking.booking_id);
		updatedBookings[bookingIndex].booking_big_boxes_data = bigBoxes;
		setBookings(updatedBookings);
		setAssignModalLoading(false);
	};

	const onClickEditBookingButton = async (booking: Booking) => {
		setEditModalLoading(true);
		await handleEditModal(bookingIndex);
		setEditModalLoading(false);
	};

	const handleUnassignBigBox = async (booking: Booking, bigBox: BigBoxRecord, index: number) => {
		const updatedBookings = [...bookings];
		updatedBookings[updatedBookings.indexOf(booking)].booking_big_boxes.splice(
			updatedBookings[updatedBookings.indexOf(booking)].booking_big_boxes.indexOf(bigBox.big_box_id),
			1
		);
		updatedBookings[updatedBookings.indexOf(booking)].booking_big_boxes_data.splice(
			updatedBookings[updatedBookings.indexOf(booking)].booking_big_boxes_data.indexOf(bigBox),
			1
		);
		setBookings(updatedBookings);

		try {
			await axiosInstance.post('/booking/booking', updatedBookings[updatedBookings.indexOf(booking)]);
			const response2 = await axiosInstance.post('/bigbox/getBigBoxByBookingID', {
				booking_id: '0'
			});
			setUnassignedBigBoxes(response2.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3
	};

	return (
		<Fragment>
			<TableRow>
				<TableCell>
					<Typography>{booking.booking_mawb_number}</Typography>
				</TableCell>
				<TableCell sx={{ maxWidth: '5px' }}>
					<Typography sx={{ whiteSpace: 'nowrap' }}>{booking.booking_date_departure}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{booking.booking_destination}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{booking.booking_destination_final}</Typography>
				</TableCell>
				<TableCell>
					<IconButton aria-label="add-big-box" size="small" onClick={() => onClickAddBigBoxButton(booking)}>
						<AddCircleOutline />
					</IconButton>
				</TableCell>
				<TableCell align="left">
					{
						<Button
							color="primary"
							variant="contained"
							size="small"
							sx={{
								backgroundColor: '#d16b2c',
								':hover': {
									backgroundColor: '#ed5f07'
								},
								whiteSpace: 'nowrap'
							}}
							onClick={() => {
								openManualTrackers(booking.booking_id);
							}}
						>
							Push
						</Button>
					}
				</TableCell>
				<TableCell align="left">
					{
						<Button
							color="primary"
							variant="contained"
							size="small"
							onClick={() => {
								getShipmentsByBookingId(booking.booking_id);
							}}
						>
							Review
						</Button>
					}
				</TableCell>
				<TableCell align="left">
					{
						<Button
							sx={{
								backgroundColor: '#2f308d',
								':hover': {
									backgroundColor: '#171878'
								},
								whiteSpace: 'nowrap'
							}}
							variant="contained"
							size="small"
							onClick={() => {
								generateWorldContainerReport(booking.booking_id);
							}}
						>
							WC Report
						</Button>
					}
				</TableCell>
				<TableCell align="left">
					{
						<Button
							sx={{
								backgroundColor: '#03C988',
								':hover': {
									backgroundColor: '#057852'
								},
								whiteSpace: 'nowrap'
							}}
							variant="contained"
							size="small"
							onClick={() => {
								clearShipmentsToEasyClear(booking.booking_id);
							}}
						>
							Clear
						</Button>
					}
				</TableCell>
				<TableCell>
					<IconButton aria-label="add-big-box" size="small" onClick={() => onClickEditBookingButton(booking)}>
						<EditRounded />
					</IconButton>
				</TableCell>
				<TableCell align="right">
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={async () => {
							setOpen(!open);
							const updatedBookings = [...bookings];
							const bigBoxes = await getBigBoxData(booking.booking_id);
							updatedBookings[bookingIndex].booking_big_boxes_data = bigBoxes;
							setBookings(updatedBookings);
						}}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						{Array.isArray(booking.booking_big_boxes_data) && !booking.booking_big_boxes_data.length ? (
							<Typography
								variant="h6"
								sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}
							>
								No big boxes
							</Typography>
						) : booking.booking_big_boxes_data ? (
							booking.booking_big_boxes_data?.map((big_box: BigBoxRecord, index: number) => (
								<>
									<Grid container direction={'row'}>
										<Grid item>
											<IconButton
												aria-label="remove-big-box"
												size="small"
												onClick={handleLeaveOpen}
											>
												<RemoveCircleOutline />
											</IconButton>
										</Grid>
										<Grid item alignSelf={'center'}>
											<Typography>{big_box.big_box_id}</Typography>
										</Grid>
									</Grid>

									{modalOpen && (
										<Modal
											open={modalOpen}
											onClose={handleLeaveClose}
											aria-labelledby="modal-modal-title"
											aria-describedby="modal-modal-description"
										>
											<Box
												sx={resetModalStyle}
												style={{ border: 'none', boxShadow: '1px 1px solid gray' }}
											>
												<Typography
													id="modal-modal-title"
													variant="h6"
													color="gray"
													component="h2"
												>
													Confirm Unassign Big Box
												</Typography>
												<Typography
													id="modal-modal-description"
													sx={{ mt: 2, color: 'gray', fontSize: '14px' }}
												>
													Are you sure you want to unassign the big box from this booking?
													This action will clear the HAWB numbers for the shipments in this
													box.
												</Typography>
												<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													<Button onClick={handleLeaveClose} sx={{ mr: 1 }} color="primary">
														Cancel
													</Button>
													<Box sx={{ flex: '1 1 auto' }} />
													<Button
														color="error"
														sx={{ fontSize: '14px' }}
														onClick={() => handleUnassignBigBox(booking, big_box, index)}
													>
														Unassign
													</Button>
												</Box>
											</Box>
										</Modal>
									)}
								</>
							))
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
								<CircularProgress disableShrink size={30} />
							</Box>
						)}
					</Collapse>
				</TableCell>
			</TableRow>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</Fragment>
	);
}

export default function BookingsTable(props: any) {
	const {
		bookings,
		setBookings,
		unAssignedBigBoxes,
		setUnassignedBigBoxes
	}: { bookings: Booking[]; setBookings: any; unAssignedBigBoxes: BigBoxRecord[]; setUnassignedBigBoxes: any } =
		props;
	const [loading, setLoading] = useState(false);
	const [openAssignModal, setOpenAssignModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [currentBookingIndex, setCurrentBookingIndex] = useState(0);
	const [assignModalLoading, setAssignModalLoading] = useState(false);
	const [editModalLoading, setEditModalLoading] = useState(false);
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const { register, getValues, setValue } = useForm({
		mode: 'all',
		defaultValues: { ...bookings[currentBookingIndex] }
	});
	const axiosInstance = axiosConfig();

	const handleAddBoxModal = (currentBookingIndex: number) => {
		setCurrentBookingIndex(currentBookingIndex);
		setOpenAssignModal(true);
	};

	const handleCloseAssignModal = () => {
		setCurrentBookingIndex(-1);
		setOpenAssignModal(false);
	};

	const handleEditModal = (currentBookingIndex: number) => {
		setCurrentBookingIndex(currentBookingIndex);
		setBookingFormValues(currentBookingIndex);
		setOpenEditModal(true);
	};

	const handleCloseEditModal = () => {
		setCurrentBookingIndex(-1);
		setOpenEditModal(false);
	};

	const setBookingFormValues = (bookingIndex: number) => {
		setValue('partitionKey', bookings[bookingIndex].partitionKey);
		setValue('sortKey', bookings[bookingIndex].sortKey);
		setValue('booking_awb_prefix', bookings[bookingIndex].booking_awb_prefix);
		setValue('booking_big_boxes', bookings[bookingIndex].booking_big_boxes);
		setValue('booking_date_arrival', bookings[bookingIndex].booking_date_arrival);
		setValue('booking_date_departure', bookings[bookingIndex].booking_date_departure);
		setValue('booking_depot_code', bookings[bookingIndex].booking_depot_code);
		setValue('booking_destination_1', bookings[bookingIndex].booking_destination_1);
		setValue('booking_destination_2', bookings[bookingIndex].booking_destination_2);
		setValue('booking_destination_3', bookings[bookingIndex].booking_destination_3);
		setValue('booking_destination_clearance_file', bookings[bookingIndex].booking_destination_clearance_file);
		setValue('booking_destination_final_code', bookings[bookingIndex].booking_destination_final_code);
		setValue('booking_destination_final', bookings[bookingIndex].booking_destination_final);
		setValue('booking_destination', bookings[bookingIndex].booking_destination);
		setValue('booking_discharge_port', bookings[bookingIndex].booking_discharge_port);
		setValue('booking_entry_clerk', bookings[bookingIndex].booking_entry_clerk);
		setValue('booking_export_country', bookings[bookingIndex].booking_export_country);
		setValue('booking_flight_1', bookings[bookingIndex].booking_flight_1);
		setValue('booking_flight_2', bookings[bookingIndex].booking_flight_2);
		setValue('booking_flight_3', bookings[bookingIndex].booking_flight_3);
		setValue('booking_id', bookings[bookingIndex].booking_id);
		setValue('booking_mawb_number', bookings[bookingIndex].booking_mawb_number);
		setValue('booking_office', bookings[bookingIndex].booking_office);
		setValue('booking_place_of_issue_date', bookings[bookingIndex].booking_place_of_issue_date);
		setValue('booking_place_of_issue', bookings[bookingIndex].booking_place_of_issue);
		setValue('booking_port_of_exit', bookings[bookingIndex].booking_port_of_exit);
		setValue('booking_port_of_loading', bookings[bookingIndex].booking_port_of_loading);
		setValue('booking_shipment_quantity', bookings[bookingIndex].booking_shipment_quantity);
		setValue('date_created', bookings[bookingIndex].date_created);
		setValue('date_last_modified', bookings[bookingIndex].date_last_modified);
		setValue('record_type', bookings[bookingIndex].record_type);
		setValue('week_number', bookings[bookingIndex].week_number ? bookings[bookingIndex].week_number : '');
	};

	const handleListItemClick = async (bigBox: BigBoxRecord, unAssignedBigBoxIndex: number) => {
		const updatedBookings = [...bookings];
		updatedBookings[currentBookingIndex].booking_big_boxes_data.push(bigBox);
		updatedBookings[currentBookingIndex].booking_big_boxes.push(bigBox.big_box_id);
		unAssignedBigBoxes.splice(unAssignedBigBoxIndex, 1);
		setOpenAssignModal(false);

		setUnassignedBigBoxes(unAssignedBigBoxes);
		try {
			await axiosInstance.post('/booking/booking', updatedBookings[currentBookingIndex]);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
		setBookings(updatedBookings);
	};

	const handleSubmit = async () => {
		setEditModalLoading(true);
		try {
			const response = await axiosInstance.post('/booking/booking', getValues());
			const updatedBookings = [...bookings];
			updatedBookings[currentBookingIndex] = response.data.data;
			setBookings(updatedBookings);
			setEditModalLoading(false);
		} catch (error) {
			// if (axiosInstance.isAxiosError(error) && error.response) {
			// 	setSnackAlert({ type: 'error', message: error.response.data.error });
			// 	setOpenSnackBar(true);
			// } else {
			// 	setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			// }
			console.log(error);
			setEditModalLoading(false);
		}
		handleCloseEditModal();
	};

	const handleOpen = (event: any) => {
		setOpenPopover(true);
		setAnchorEl(event.currentTarget);
		setTimeout(() => {
			setOpenPopover(false);
		}, 500);
	};

	return (
		<div>
			<TableContainer component={Paper} sx={{ backgroundColor: '#F5F5F5' }}>
				<Table>
					<TableHead
						sx={{
							'& th': {
								fontWeight: 'bold'
							}
						}}
					>
						<TableRow>
							<TableCell>MAWB</TableCell>
							<TableCell>Shipment Date</TableCell>
							<TableCell>Destination Country</TableCell>
							<TableCell>Destination Airport</TableCell>
							<TableCell>Assign Big Box</TableCell>
							<TableCell>Trackers</TableCell>
							<TableCell>Review Shipments</TableCell>
							<TableCell>WC Report</TableCell>
							<TableCell>Easy Clear</TableCell>
							<TableCell>Edit</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{bookings && Array.isArray(bookings) ? (
							bookings.map((booking: Booking, bookingIndex: number) => (
								<ReviewRow
									booking={booking}
									bookings={bookings}
									bookingIndex={bookingIndex}
									setBookings={setBookings}
									handleAddBoxModal={handleAddBoxModal}
									setAssignModalLoading={setAssignModalLoading}
									setEditModalLoading={setEditModalLoading}
									handleEditModal={handleEditModal}
									unAssignedBigBoxes={unAssignedBigBoxes}
									setUnassignedBigBoxes={setUnassignedBigBoxes}
								/>
							))
						) : (
							<></>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog onClose={handleCloseAssignModal} open={openAssignModal}>
				<DialogTitle>Assign Big Box To Booking</DialogTitle>
				<DialogContent dividers={true}>
					{assignModalLoading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
							<CircularProgress disableShrink size={30} />
						</Box>
					) : unAssignedBigBoxes.length > 0 ? (
						<List>
							{unAssignedBigBoxes.map((unAssignedBigBox, index) => (
								<ListItem key={unAssignedBigBox.big_box_id}>
									<ListItemButton onClick={() => handleListItemClick(unAssignedBigBox, index)}>
										<ListItemText primary={unAssignedBigBox.big_box_id} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					) : (
						<Typography>No Boxes found</Typography>
					)}
				</DialogContent>
			</Dialog>
			<Dialog onClose={handleCloseEditModal} open={openEditModal}>
				<DialogTitle>Edit Booking</DialogTitle>

				<DialogContent dividers={true}>
					{editModalLoading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
							<CircularProgress disableShrink size={30} />
						</Box>
					) : (
						<>
							<Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} mb={2}>
								<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
									<h3>{getValues().booking_id}</h3>
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
									<CopyToClipboard text={getValues().booking_id}>
										<IconButton
											color="primary"
											component="span"
											onClick={handleOpen}
											size={'small'}
										>
											<CopyAll />
											<Popover
												open={openPopover}
												anchorEl={anchorEl}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right'
												}}
											>
												<div
													style={{
														color: 'white',
														backgroundColor: 'black'
														// padding: '6px'
													}}
												>
													Copied!
												</div>
											</Popover>
										</IconButton>
									</CopyToClipboard>
								</Grid>
							</Grid>
							<Grid container alignItems="center" direction="column" rowSpacing={2}>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'AWB Prefix'} fullWidth {...register('booking_awb_prefix')} />
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'MAWB'} fullWidth {...register('booking_mawb_number')} />
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'Customs Office'} fullWidth {...register('booking_office')} />
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'Flight 1'} fullWidth {...register('booking_flight_1')} />
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'Flight 2'} fullWidth {...register('booking_flight_2')} />
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'Flight 3'} fullWidth {...register('booking_flight_3')} />
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Destination 1'}
											fullWidth
											{...register('booking_destination_1')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Destination 2'}
											fullWidth
											{...register('booking_destination_2')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Destination 3'}
											fullWidth
											{...register('booking_destination_3')}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Shipment Date'}
											fullWidth
											{...register('booking_date_departure')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Date of Arrival'}
											fullWidth
											{...register('booking_date_arrival')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField label={'Week Number'} fullWidth {...register('week_number')} />
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
										<TextField
											label={'Final Destination'}
											fullWidth
											{...register('booking_destination_final')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
										<TextField
											label={'Final Destination Code'}
											fullWidth
											{...register('booking_destination_final_code')}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Entry Clerk'}
											fullWidth
											{...register('booking_entry_clerk')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Place of Issue'}
											fullWidth
											{...register('booking_place_of_issue')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
										<TextField
											label={'Place of Issue Date'}
											fullWidth
											{...register('booking_place_of_issue_date')}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
										<TextField
											label={'Discharge Port'}
											fullWidth
											{...register('booking_discharge_port')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
										<TextField label={'Depot Code'} fullWidth {...register('booking_depot_code')} />
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
										<TextField
											label={'Port of Loading'}
											fullWidth
											{...register('booking_port_of_loading')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
										<TextField
											label={'Port of Exit'}
											fullWidth
											{...register('booking_port_of_exit')}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
									mb={2}
								>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
										<TextField
											label={'Country of export'}
											fullWidth
											{...register('booking_export_country')}
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
										<TextField
											label={'Destination Country'}
											fullWidth
											{...register('booking_destination')}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									spacing={2}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Grid item xs={10} sm={10} md={12} lg={6} xl={6} alignSelf={'center'}>
										<Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
											Update Booking
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
}
