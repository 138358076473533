import { Link } from '@mui/material';

export interface DownloadCSVProps {
	data: string;
	filename: string;
}

export default function DownloadCSV(props: DownloadCSVProps) {
	const { data, filename } = props;

	const convertToCSV = (objArray: Record<string, string>[]): string => {
		if (objArray.length === 0) return '';

		const headers = Object.keys(objArray[0]);
		let str = headers.join(',') + '\r\n';

		for (const obj of objArray) {
			let line = headers.map(key => obj[key]).join(',');
			str += line + '\r\n';
		}

		return str;
	};

	const parseCSV = (csv: string): Record<string, string>[] => {
		const [header, ...rows] = csv.split('\n');
		const headers = header.split(',');

		return rows.map(row => {
			const values = row.split(',');
			return headers.reduce((acc, key, i) => {
				acc[key] = values[i];
				return acc;
			}, {} as Record<string, string>);
		});
	};

	const downloadCSV = () => {
		const stringCSV = parseCSV(data);
		const csvData = new Blob([convertToCSV(stringCSV)], { type: 'text/csv' });
		const csvURL = URL.createObjectURL(csvData);
		const link = document.createElement('a');
		link.href = csvURL;
		link.download = `${filename}.csv`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Link component={'button'} onClick={downloadCSV}>
			Download CSV
		</Link>
	);
}
