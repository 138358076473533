import LearnMore from '@components/Common/LearnMore';
import TNAlerts from '@components/Common/TNAlerts';
import { axiosConfig } from '@constants/axios';
import {
	BUTTON,
	createElementId,
	DELETE,
	EDIT,
	EDIT_MODAL,
	HEADING,
	LINK,
	PRODUCT_LIBRARY,
	PRODUCT_TABLE,
	TABLE_BODY,
	TABLE_HEAD,
	TEXTFIELD
} from '@constants/id';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	Modal,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { invalidChapters, warningChapters } from '@pages/Products/HsCodeRestrictions';
import { ProductRecord } from '@tunl-types/product';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ProductsTableProps {
	products: ProductRecord[];
	updateProduct: (updatedProduct: ProductRecord) => void;
}

function Row(props: any) {
	const { product, onEdit, onDelete } = props;

	const handleEditClick = () => {
		onEdit(product);
	};

	const handleDeleteClick = () => {
		onDelete(product);
	};

	return (
		<Fragment>
			<TableRow sx={{ borderBottom: 1, borderColor: '#dadada' }} key={product._id}>
				<TableCell align="left" sx={{ paddingLeft: 5 }}>
					<Typography>{product.description}</Typography>
				</TableCell>
				<TableCell align="center">
					<Typography>{product.hs_code}</Typography>
				</TableCell>
				<TableCell align="center">
					<Typography>{product.hts_code}</Typography>
				</TableCell>
				<TableCell align="center">
					<Typography>{product.unit_weight}</Typography>
				</TableCell>
				<TableCell align="center">
					<Typography>{product.unit_price}</Typography>
				</TableCell>
				<TableCell align="center">
					<Typography>{product.origin_country}</Typography>
				</TableCell>
				<TableCell align="center">
					<IconButton
						id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, BUTTON, EDIT, product.description])}
						aria-label="expand row"
						size="small"
						onClick={handleEditClick}
					>
						<Edit />
					</IconButton>
				</TableCell>
				<TableCell align="center">
					<IconButton
						id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, BUTTON, DELETE, product.description])}
						aria-label="expand row"
						size="small"
						onClick={handleDeleteClick}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		</Fragment>
	);
}

export default function ProductsTable(props: ProductsTableProps) {
	const { products, updateProduct } = props;
	const [updatedProducts, setUpdatedProducts] = useState<ProductRecord[]>(products);
	const [openModal, setOpenModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductRecord | null>(null);
	const [itemDescription, setItemDescription] = useState('');
	const [classificationResult, setClassificationResult] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [customsDescription, setCustomsDescription] = useState('');
	const [showCustomsDescription, setShowCustomsDescription] = useState(false);
	const [htsCodeError, setHtsCodeError] = useState('');
	const [alertOpen, setAlertOpen] = useState(false);
	const [confidence, setConfidence] = useState(0);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [htsCodeWarning, setHtsCodeWarning] = useState('');
	const [getHtsCode, setHtsCode] = useState('');
	const [codeError, setCodeError] = useState(false) as any;
	const [codeWarning, setCodeWarning] = useState(false) as any;
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const axiosInstance = axiosConfig();
	const [error, setError] = useState(false);
	const [message, setMessage] = useState('');

	const classifyItem = async () => {
		setIsLoading(true);

		try {
			const response = await axiosInstance.post('/zonos/zonosClassify', {
				item: {
					id: '',
					description: itemDescription
				},
				ship_to_country: 'US'
			});

			const formattedHtsCode = response.data.data?.classified_code.replace(/\./g, '');
			const chapter = response.data.data?.codes[3].code;
			setConfidence(response.data.data?.confidence_score.toFixed(2));
			setValue('hts_code', formattedHtsCode);
			setHtsCode(formattedHtsCode);
			setCustomsDescription(response.data.data?.combined_description);
			const htsCode = checkCodeStartsWith(chapter);
			setHtsCodeError(htsCode);
			const htsCodeWarning = checkWarningCodes(chapter);
			setHtsCodeWarning(htsCodeWarning);
			setClassificationResult('');
		} catch (error) {
			console.error('API request error:', error);
			setClassificationResult(
				`Error: Unable to lookup the HS Code for this item. Please try again later.\n ${error}`
			);
		} finally {
			setIsLoading(false);
			setShowCustomsDescription(true);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues
	} = useForm();

	useEffect(() => {
		setUpdatedProducts(products);
	}, [products]);

	useEffect(() => {
		if (!openModal) {
			setShowCustomsDescription(false);
			setItemDescription('');
			setValue('description', '');
			setValue('unit_weight', ' ');
			setValue('unit_price', ' ');
			setValue('origin_country', '');
			setHtsCodeError('');
			setHtsCodeWarning('');
			setClassificationResult('');
		} else if (classificationResult !== '') {
			setCustomsDescription('');
			setHtsCodeError('');
			setHtsCodeWarning('');
			setHtsCode('');
			setCodeError(false);
			setCodeWarning(false);
		}
	}, [openModal, classificationResult]);

	const handleEditClick = (product: ProductRecord) => {
		setSelectedProduct(product);
		setOpenModal(true);

		setValue('description', product.description);
		setValue('origin_country', product.origin_country);
		setValue('hts_code', product.hts_code);
		setValue('unit_weight', product.unit_weight);
		setValue('unit_price', product.unit_price);
	};

	const handleOnDelete = async (product: ProductRecord) => {
		setDeleteDialogOpen(true);
		setSelectedProduct(product);
	};

	const handleDeleteClick = async (product: ProductRecord) => {
		try {
			setIsLoading(true);
			if (product) {
				const response = await axiosInstance.post('/product/deleteProductRecordById', {
					_id: product._id
				});
				if (response.data && response.data.message) {
					setUpdatedProducts((prevProducts: ProductRecord[]) =>
						prevProducts.filter(p => p._id !== product._id)
					);
					setIsLoading(false);
					setDeleteDialogOpen(false);
					setMessage('Succesfully deleted product.');
					setAlertOpen(true);
				}
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setDeleteDialogOpen(false);
			setError(true);
			setMessage('Error deleting product.');
			setAlertOpen(true);
		}
	};

	useEffect(() => {
		htsCodeError === 'Invalid Code'
			? setCodeError(true)
			: htsCodeWarning === 'Warning Code'
			? setCodeWarning(true)
			: setCodeError(false) || setCodeWarning(false);
	}, [htsCodeError, htsCodeWarning]);

	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const resetModalStyleSm = {
		position: 'absolute' as 'absolute',
		top: '10%',
		left: '10%',
		right: '10%',
		bottom: 'auto',
		transform: 'auto',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const handleUpdateProduct = (data: any) => {
		const updatedProduct: ProductRecord = {
			...selectedProduct!,
			description: data.description,
			origin_country: data.origin_country,
			hts_code: data.hts_code,
			unit_weight: data.unit_weight,
			unit_price: data.unit_price
		};

		if (htsCodeError === '') {
			setError(true);
			setMessage(htsCodeError);
			updateProduct(updatedProduct);
			setOpenModal(false);
		} else {
			setAlertOpen(true);
		}
	};

	const checkCodeStartsWith = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return getInvalidChapters.includes(code) ? 'Invalid Code' : '';
	};

	const checkWarningCodes = (code: string): string => {
		const getWarningChapters = warningChapters;
		return getWarningChapters.includes(code) ? 'Warning Code' : '';
	};

	const checkChapterRestriction = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return code.length > 5 && code.length < 11
			? getInvalidChapters.includes(code.slice(0, 2))
				? 'Invalid Code'
				: ''
			: 'Invalid length';
	};

	const checkChapterWarning = (code: string): string => {
		const getWarningChapters = warningChapters;
		return code.length > 5 && code.length < 11
			? getWarningChapters.includes(code.slice(0, 2))
				? 'Warning Code'
				: ''
			: 'Invalid length';
	};

	const handleHtsCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const htsCode = checkChapterRestriction(event.target.value.trim());
		setHtsCodeError(htsCode);
		setValue('hts_code', event.target.value.trim());
	};

	const handleHtsCodeWarning = (event: React.ChangeEvent<HTMLInputElement>) => {
		const htsCode = checkChapterWarning(event.target.value.trim());
		setHtsCodeWarning(htsCode);
		setValue('hts_code', event.target.value.trim());
		setHtsCode(event.target.value.trim());
	};

	return (
		<TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3, mb: 5 }}>
			<Table>
				<TableHead>
					<Grid container sx={{ p: 2, fontSize: '1.2em' }}>
						<Typography id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, HEADING, 'products'])}>
							Products
						</Typography>
					</Grid>
					<TableRow>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'description'])}
							align="left"
							sx={{ paddingLeft: 5, fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Description
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'hs_code'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							HS Code
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'hts_code'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							HTS Code
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'unit_weight'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Weight
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'unit_price'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Value
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'origin_country'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Country of Origin
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'edit'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Edit
						</TableCell>
						<TableCell
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, TABLE_HEAD, 'delete'])}
							align="center"
							sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
						>
							Delete
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{updatedProducts.length ? (
						updatedProducts.map((productRow: ProductRecord) => (
							<Row
								product={productRow}
								onEdit={handleEditClick}
								key={productRow._id}
								setDeleteDialogOpen={setDeleteDialogOpen}
								setUpdatedProducts={setUpdatedProducts}
								onDelete={handleOnDelete}
							/>
						))
					) : (
						<TableRow>
							<TableCell colSpan={12}>
								<Grid container justifyContent="center" alignItems="center">
									<Typography
										id={createElementId([
											PRODUCT_LIBRARY,
											PRODUCT_TABLE,
											TABLE_BODY,
											'no_products'
										])}
									>
										No products added yet!
									</Typography>
								</Grid>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ overflow: 'auto' }}
			>
				<div
					style={{
						display: 'block',
						justifyContent: 'center',
						alignContent: 'center',
						marginBottom: '60px',
						maxWidth: 1200,
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
				>
					{selectedProduct && (
						<Box>
							<Box
								sx={isXsSmMd ? resetModalStyle : resetModalStyleSm}
								style={{
									border: 'none',
									boxShadow: '1px 1px solid gray',
									overflow: 'auto',
									maxHeight: 800
								}}
							>
								<Typography
									id={createElementId([
										PRODUCT_LIBRARY,
										PRODUCT_TABLE,
										EDIT_MODAL,
										HEADING,
										'edit_product'
									])}
									variant="h6"
									component="h2"
								>
									Edit Product
								</Typography>
								<Typography
									id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, EDIT_MODAL, 'description'])}
									sx={{ mt: 2, color: 'gray', fontSize: '14px' }}
								>
									Reduce the risk of customs delays with the right harmonized code and a detailed item
									description (e.g. men’s cotton shirts).
								</Typography>
								<Box sx={{ pt: 2 }}>
									<form onSubmit={handleSubmit(handleUpdateProduct)}>
										<Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
											<Tooltip title="Enter a detailed product description for customs e.g. men’s cotton shirts">
												<TextField
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														TEXTFIELD,
														'description'
													])}
													required
													label="Description"
													{...register('description', { required: 'Required' })}
													error={errors.description ? true : false}
													helperText={errors.description ? 'Description is required' : ''}
													defaultValue={selectedProduct.description}
													onChange={e => {
														if (e.target.value === '') {
															setShowCustomsDescription(false);
														}
														setItemDescription(e.target.value);
													}}
													fullWidth
												/>
											</Tooltip>
											<Grid item mb={2}>
												<Button
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														BUTTON,
														'get_hs_code'
													])}
													variant="contained"
													fullWidth
													color="primary"
													onClick={classifyItem}
													disabled={isLoading || itemDescription === ''}
													style={{ marginTop: '20px' }}
												>
													Get HS Code
												</Button>
											</Grid>
											{showCustomsDescription && classificationResult === '' ? (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TNAlerts
														severity={
															confidence < 0.35
																? 'warning'
																: confidence < 0.5
																? 'warning'
																: confidence < 0.71
																? 'warning'
																: confidence < 0.9
																? 'info'
																: 'success'
														}
														title={'Customs Description'}
														message={customsDescription}
														confidence={confidence}
													/>
												</Grid>
											) : (
												classificationResult &&
												!customsDescription && (
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<TNAlerts
															severity="error"
															title="HS Code Lookup Failed!"
															message={classificationResult}
														/>
													</Grid>
												)
											)}
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1 }}>
												{htsCodeError && codeError && (
													<TNAlerts
														severity="error"
														title="Restricted Code"
														message={
															(
																<>
																	TUNL doesn't ship commodities under this HS/HTS
																	code. For assistance or help, please contact{' '}
																	<Link
																		id={createElementId([
																			PRODUCT_LIBRARY,
																			PRODUCT_TABLE,
																			EDIT_MODAL,
																			LINK,
																			'support'
																		])}
																		href="mailto:support@tunl.to"
																	>
																		support@tunl.to
																	</Link>
																</>
															) as any
														}
													/>
												)}
												{htsCodeWarning && codeWarning && (
													<TNAlerts
														severity="warning"
														title="Warning Code"
														message={
															(
																<>
																	The commodities{' '}
																	<strong>(HTS Code: {getHtsCode})</strong> requires
																	permission or extra documentation to ship. Please
																	get in touch with TUNL Support before proceeding.
																</>
															) as any
														}
													/>
												)}
											</Grid>
											<Tooltip
												title={
													<span>
														HTS Code: “US customs identifier of goods.{' '}
														<LearnMore
															id={createElementId([
																PRODUCT_LIBRARY,
																PRODUCT_TABLE,
																EDIT_MODAL,
																LINK,
																'hts_code'
															])}
															href="https://help.tunl.to/en/article/what-is-an-hs-code-and-hts-code-sez7qh/"
															inline={true}
															style={{ color: 'white' }}
														/>
													</span>
												}
											>
												<TextField
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														TEXTFIELD,
														'hts_code'
													])}
													label="HTS Code"
													{...register('hts_code', {
														minLength: {
															value: 10,
															message:
																htsCodeError || 'HTS Code must at least be 10 digits'
														},
														maxLength: {
															value: 10,
															message:
																htsCodeError || 'HTS Code must not exceed 10 digits'
														},
														onChange: event =>
															setValue('hts_code', event.target.value.trim())
													})}
													required
													value={openModal ? getValues('hts_code') : ''}
													onChange={(e: any) => {
														handleHtsCodeChange(e);
														handleHtsCodeWarning(e);
													}}
													error={!!errors.hts_code ? true : false || !!htsCodeError}
													helperText={
														errors.hts_code
															? (errors.hts_code.message as string)
															: htsCodeError
													}
													defaultValue={selectedProduct.hts_code}
													fullWidth
													sx={{ mt: 2 }}
												/>
											</Tooltip>
											<Tooltip title="Enter the product weight in kg e.g. 0.2">
												<TextField
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														TEXTFIELD,
														'unit_weight'
													])}
													type="number"
													label="Weight"
													{...register('unit_weight', {
														pattern: {
															value: /^[0-9]+(\.[0-9]{1,2})?$/,
															message: 'Weight must have up to 2 decimal places'
														}
													})}
													inputProps={{
														min: 0.1,
														step: '0.01'
													}}
													error={errors.unit_weight ? true : false}
													helperText={
														errors.unit_weight ? (errors.unit_weight.message as string) : ''
													}
													defaultValue={selectedProduct.unit_weight}
													fullWidth
													sx={{ mt: 2 }}
												/>
											</Tooltip>
											<Tooltip title="Enter the product value in the currency you use on your invoice e.g. 1000.">
												<TextField
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														TEXTFIELD,
														'unit_price'
													])}
													type="number"
													label="Value"
													defaultValue={selectedProduct.unit_price}
													fullWidth
													sx={{ mt: 2 }}
													{...register('unit_price', {
														pattern: {
															value: /^[0-9]+(\.[0-9]{1,2})?$/,
															message: 'Value must have up to 2 decimal places'
														}
													})}
													inputProps={{
														step: '0.01'
													}}
													error={errors.unit_price ? true : false}
													helperText={
														errors.unit_price ? (errors.unit_price.message as string) : ''
													}
												/>
											</Tooltip>
											<Tooltip title="Enter the country code where the product was last manufactured or underwent significant alterations. E.g. use 'ZA' for South Africa.">
												<TextField
													id={createElementId([
														PRODUCT_LIBRARY,
														PRODUCT_TABLE,
														EDIT_MODAL,
														TEXTFIELD,
														'origin_country'
													])}
													required
													fullWidth
													defaultValue={selectedProduct.origin_country}
													label="Country of Origin"
													sx={{ mt: 2 }}
													{...register('origin_country', {
														required: 'Required',
														pattern: {
															value: /^[a-z][A-Z]{1}$/i,
															message: 'Invalid input. Country must be 2 letter code'
														}
													})}
													error={!!errors?.origin_country}
													helperText={
														errors?.origin_country
															? 'Invalid input. Country must be 2 letter code'
															: null
													}
												/>
											</Tooltip>
										</Grid>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex-end',
												flexDirection: 'row',
												pt: 2
											}}
										>
											<Button
												id={createElementId([
													PRODUCT_LIBRARY,
													PRODUCT_TABLE,
													EDIT_MODAL,
													BUTTON,
													'cancel'
												])}
												onClick={() => setOpenModal(false)}
												sx={{ mr: 1 }}
												color="primary"
											>
												Cancel
											</Button>
											<Button
												id={createElementId([
													PRODUCT_LIBRARY,
													PRODUCT_TABLE,
													EDIT_MODAL,
													BUTTON,
													'save'
												])}
												type="submit"
												sx={{ mr: 1 }}
												color="primary"
											>
												Save
											</Button>
										</Box>
									</form>
								</Box>
							</Box>
						</Box>
					)}
				</div>
			</Modal>

			{selectedProduct && (
				<Dialog
					open={deleteDialogOpen}
					onClose={() => {
						setDeleteDialogOpen(false);
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					fullWidth={true}
				>
					<DialogTitle id="modal-modal-title">Delete Product</DialogTitle>
					<DialogContent>
						{isLoading ? (
							<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
								<CircularProgress disableShrink size={30} />
							</Box>
						) : (
							<DialogContentText>
								Are you sure you want to delete this product? This action cannot be undone.
							</DialogContentText>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, BUTTON, 'back'])}
							disabled={isLoading}
							onClick={() => {
								setDeleteDialogOpen(false);
							}}
						>
							Back
						</Button>
						<Button
							id={createElementId([PRODUCT_LIBRARY, PRODUCT_TABLE, BUTTON, 'delete'])}
							disabled={isLoading}
							color="error"
							variant="contained"
							onClick={() => {
								handleDeleteClick(selectedProduct);
							}}
						>
							Delete Product
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<Snackbar
				open={alertOpen}
				autoHideDuration={4000}
				onClose={() => {
					setAlertOpen(false);
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert
					onClose={() => {
						setAlertOpen(false);
					}}
					severity={error ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{message}
				</Alert>
			</Snackbar>
		</TableContainer>
	);
}
