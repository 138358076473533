import {
	Box,
	Button,
	FormControl,
	Grid,
	ListItemButton,
	Modal,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Collapse,
	IconButton
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import TrackerMerchant from '@pages/Merchant/TrackerMerchant';

export default function CustomsTable(props: any) {
	return (
		<>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Merchant Name</TableCell>
							<TableCell align="center">Event Status</TableCell>
							<TableCell align="center">To name</TableCell>
							<TableCell align="right">Tracking</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.shipments.map((shipment: any) => (
							<>
								<TableRow
									key={shipment.shipment_id}
									onClick={() => props.handleRowClick(shipment)}
									selected={props.selectedShipment === shipment}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{shipment?.merchant_name}
									</TableCell>
									<TableCell align="center">{shipment?.event_status_detail}</TableCell>
									<TableCell align="center">{shipment?.address_to_name}</TableCell>

									<TableCell align="right" sx={{ width: '10%' }}>
										<IconButton
											aria-label="expand row"
											size="small"
											onClick={() => props.setOpenRow(!props.openRow)}
										>
											{props.openRow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
										</IconButton>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
										{props.selectedShipment === shipment ? (
											<Collapse in={props.openRow} timeout="auto" unmountOnExit>
												<div>
													<TrackerMerchant trackingCode={shipment.carrier_track_code} />
												</div>
											</Collapse>
										) : null}
									</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Modal
				open={props.bulkModalOpen}
				onClose={props.bulkLeaveClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={props.resetModalStyle}
					style={{
						border: 'none',
						boxShadow: '1px 1px solid gray'
					}}
				>
					<Typography id="modal-modal-title" variant="h6" color="gray" component="h2">
						Push Manual Tracker Status
					</Typography>

					{props.bulkDialogBox ? (
						<>
							<Grid item xs={10} sm={10} md={10} lg={10} xl={10} justifyContent="center">
								<TextField
									label="Custom Heading"
									type="text"
									value={props.customListValue}
									onChange={e => props.setCustomListValue(e.target.value)}
									fullWidth
									margin="normal"
								/>
								<TextField
									label="Custom message"
									type="text"
									value={props.customMessage}
									onChange={e => props.setCustomMessage(e.target.value)}
									fullWidth
									margin="normal"
								/>
								<TextField
									label="Custom location"
									type="text"
									value={props.customLocation}
									onChange={e => props.setCustomLocation(e.target.value)}
									fullWidth
									margin="normal"
								/>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										pt: 2,
										justifyContent: 'space-between'
									}}
								>
									<Button
										sx={{
											backgroundColor: '#f73378',
											':hover': {
												backgroundColor: '#f50057'
											}
										}}
										variant="contained"
										onClick={() => {
											props.setBulkDialogBox(false);
										}}
									>
										Back to list
									</Button>
									<Button
										sx={{
											backgroundColor: '#03C988',
											':hover': {
												backgroundColor: '#057852'
											}
										}}
										variant="contained"
										onClick={() => {
											props.handleBulkUpdates(props.customListValue, props.customMessage);
											props.setBulkModalOpen(false);
										}}
									>
										Push Tracker
									</Button>
								</Box>
							</Grid>
						</>
					) : (
						<>
							<FormControl variant="standard" sx={{ paddingY: 2 }} fullWidth>
								<Select
									renderValue={listValue => listValue || 'Select an option'}
									open={props.dialogOpen}
									onOpen={() => props.setDialogOpen(true)}
									onClose={() => props.setDialogOpen(false)}
									fullWidth
									value={props.listValue}
								>
									<ListItemButton
										onClick={() => {
											props.setListValue(props.enRoute);
											props.setListMessage(props.enRouteMessage);
											props.setDialogOpen(false);
										}}
									>
										Shipment departed on flight to the USA
									</ListItemButton>
									<ListItemButton
										onClick={() => {
											props.setListValue(props.arrived);
											props.setListMessage(props.arrivedMessage);
											props.setDialogOpen(false);
										}}
									>
										Parcel arrived in the USA and customs cleared
									</ListItemButton>
									<ListItemButton
										onClick={() => {
											props.setListValue(props.scanned);
											props.setListMessage(props.scannedMessage);
											props.setDialogOpen(false);
										}}
									>
										Shipment ready for customs clearance out of South Africa.
									</ListItemButton>
									<ListItemButton
										onClick={() => {
											props.setListValue(props.cleared);
											props.setListMessage(props.clearedMessage);
											props.setDialogOpen(false);
										}}
									>
										Parcel released from South African customs
									</ListItemButton>
									<ListItemButton
										onClick={() => {
											props.setBulkDialogBox(true);
										}}
									>
										Write custom message
									</ListItemButton>
								</Select>
							</FormControl>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 2
								}}
							>
								<Button
									sx={{
										backgroundColor: '#03C988',
										':hover': {
											backgroundColor: '#057852'
										}
									}}
									variant="contained"
									onClick={() => {
										props.handleBulkUpdates(props.listValue, props.listMessage);
										props.setBulkModalOpen(false);
									}}
									disabled={props.listValue.length === 0}
								>
									Push Tracker
								</Button>
							</Box>
						</>
					)}
				</Box>
			</Modal>
		</>
	);
}
