import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
	Alert,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid2 as Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Radio,
	Stack,
	Typography,
	styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ServiceInformation } from './BuyLabel/ServiceInformation';
import { isParcelValid } from './Parcel/ParcelContainer';
import posthog from 'posthog-js';
import { useShipmentContext } from '../hooks/useShipmentContext';
import { ConsignmentRate } from '../types/rates';
import { axiosConfig } from '@constants/axios';
import { IConsignment, ParcelBase } from '../types/consignment';
import { ShipmentActionTypes } from '../context/actions';
import { theme } from '@util/theme';

interface Props {}

export default function Services({}: Props) {
	const { state, dispatch } = useShipmentContext();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [selectedService, setSelectedService] = useState('');
	const [services, setServices] = useState<ConsignmentRate[]>([]);

	const axiosInstance = axiosConfig();
	const getEconomyRatesForCarriers = async (consignmentInput: IConsignment) => {
		if (consignmentInput.toAddress.country !== 'US') return null;
		return axiosInstance
			.post('/api/v1/rates/economy', consignmentInput)
			.then(result => {
				return result.data.data;
			})
			.catch(e => {
				console.log(e);
				return null;
			});
	};
	const getExpressRatesForCarriers = async (consignmentInput: IConsignment) => {
		return axiosInstance.post('/api/v1/rates/express', consignmentInput).then(result => {
			return result.data.data;
		});
	};

	const getRatesForCarriers = async () => {
		try {
			setLoading(true);

			const consignmentInput: IConsignment = {
				fromAddress: {
					...state.fromAddress
				},
				toAddress: {
					...state.toAddress
				},
				parcels: state.parcels.map(parcel => {
					const parcelInput: ParcelBase = {
						length: parcel.length,
						width: parcel.width,
						height: parcel.height,
						weight: parcel.weight
					};
					return parcelInput;
				})
			};

			const response: ConsignmentRate[] = await Promise.all([
				getExpressRatesForCarriers(consignmentInput),
				getEconomyRatesForCarriers(consignmentInput)
			]).then(results => {
				return results.filter(rate => rate !== null).flat();
			});
			setServices(response);
			setLoading(false);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	};

	const resetOptionService = () => {
		setSelectedService('');
		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'services',
				complete: false
			}
		});

		dispatch({
			type: ShipmentActionTypes.UPDATE_RATES,
			payload: {
				selectedService: {
					source: '',
					sourceRemoteId: '',
					carrier: '',
					partnerService: '',
					currency: '',
					service: '',
					deliveryEstimate: '',
					carrierAccountId: '',
					serviceOptions: '',
					rate: 0,
					breakdown: [
						{
							amount: 0,
							carrierCode: '',
							type: '',
							baseAmount: 0
						}
					],
					subtotals: {
						fuelSurcharge: 0,
						insuranceCost: 0,
						otherSurcharge: 0,
						shipping: 0
					}
				},
				returnedRates: [
					{
						source: '',
						sourceRemoteId: '',
						carrier: '',
						partnerService: '',
						currency: '',
						service: '',
						deliveryEstimate: '',
						carrierAccountId: '',
						serviceOptions: '',
						rate: 0,
						breakdown: [
							{
								amount: 0,
								carrierCode: '',
								type: '',
								baseAmount: 0
							}
						],
						subtotals: {
							fuelSurcharge: 0,
							insuranceCost: 0,
							otherSurcharge: 0,
							shipping: 0
						}
					}
				]
			}
		});
	};

	useEffect(() => {
		if (
			(state.triggers.validAddress || state.triggers.validParcelDimensions) &&
			(state.triggers.validAddress || state.formSections.toAddress.complete) &&
			(state.triggers.validParcelDimensions || isParcelValid(state.parcels))
		) {
			dispatch({
				type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
				payload: {
					key: 'fetchRates',
					value: true
				}
			});
		}
	}, [state.triggers.validAddress, state.triggers.validParcelDimensions, state.formSections.toAddress.complete]);

	useEffect(() => {
		const triggerGetRates = async () => {
			if (state.triggers.fetchRates) {
				if (state.formSections.services.complete) {
					resetOptionService();
				}
				if (error) {
					setError(false);
				}
				await getRatesForCarriers().finally(() => {
					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'fetchRates',
							value: false
						}
					});

					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'validAddress',
							value: false
						}
					});
					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'validParcelDimensions',
							value: false
						}
					});
				});
			}
		};

		triggerGetRates();
	}, [state.triggers.fetchRates]);

	const handleServiceChange = (service: any) => {
		setSelectedService(service.carrier);
		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'services',
				complete: true
			}
		});

		dispatch({
			type: ShipmentActionTypes.UPDATE_RATES,
			payload: {
				returnedRates: services,
				selectedService: service
			}
		});
		posthog.capture('service_selected', {
			service: service,
			addressFrom: state.fromAddress,
			parcels: state.parcels,
			carrier: state.rates.selectedService.partnerService,
			carrierService: state.rates.selectedService.carrier,
			rate: rateData
		});
	};

	const rateData: ConsignmentRate[] = services
		?.sort((a: ConsignmentRate, b: ConsignmentRate) => a.rate - b.rate)
		?.map((rate: ConsignmentRate) => {
			const thumbnail =
				rate.partnerService === 'GROUND'
					? 'ground.png'
					: rate.partnerService === 'UPSSaver'
					? 'UPS.jpg'
					: 'FedEx.jpg';
			return {
				...rate,
				image: thumbnail
			};
		});

	return (
		<Card sx={{ paddingBottom: '2px', mb: 1 }}>
			<CardHeader
				title="Shipping service"
				sx={{ backgroundColor: theme.palette.grey[100] }}
				titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
				avatar={
					state.formSections.services.complete ? (
						<CheckCircleIcon sx={{ color: 'green' }} />
					) : (
						<CheckCircleOutlineIcon sx={{ color: 'text.secondary' }} />
					)
				}
			/>
			<CardContent sx={{ paddingBottom: '0px !important', padding: loading ? '8px' : '0' }}>
				<Grid container direction="row" size={12} justifyContent={'center'} alignContent={'center'}>
					{loading ? (
						<CircularProgress />
					) : error ? (
						<Alert variant="outlined" severity="error" sx={{ width: '100%', margin: '16px' }}>
							<Stack spacing={1}>
								<Typography sx={{ fontWeight: 'bold' }}>FAILED TO FETCH RATES</Typography>
								<Typography>Check the following:</Typography>
								<Typography sx={{ pl: 2 }}>&bull; Address information.</Typography>
								<Typography sx={{ pl: 2 }}>&bull; Parcel dimensions.</Typography>
							</Stack>
						</Alert>
					) : (
						<>
							{rateData.length > 0 ? (
								<List>
									{rateData?.map((service: ConsignmentRate, index: number) => (
										<ListItem
											key={index}
											disablePadding
											onClick={e => {
												handleServiceChange(service);
											}}
										>
											<ListItemButton dense>
												<ListItemIcon sx={{ px: 0 }}>
													<Radio checked={selectedService === service.carrier} />
												</ListItemIcon>
												<ListItemText>
													<ServiceInformation service={service} />
												</ListItemText>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							) : (
								<Alert variant="outlined" severity="warning" sx={{ width: '100%', margin: '16px' }}>
									<Stack spacing={1}>
										<Typography sx={{ fontWeight: 'bold' }}>RATES UNAVAILABLE</Typography>
										<Typography>Fix the following to get rates:</Typography>
										<Typography sx={{ pl: 2 }}>&bull; Complete address information.</Typography>
										<Typography sx={{ pl: 2 }}>&bull; Complete parcel dimensions.</Typography>
									</Stack>
								</Alert>
							)}
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}
