import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Grid2 as Grid,
	MenuItem,
	Select,
	SelectChangeEvent
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PickupType } from '../types/pickup';
import { useShipmentContext } from '../hooks/useShipmentContext';
import { ShipmentActionTypes } from '../context/actions';
import { BootstrapInput } from './BootstrapInput';
import { getMaxDateTime, getMinDateTime } from '../utils/pickup';
import { pickupDateFedex, pickupDateUPS } from '@util/date';
import {
	CHECKOUT,
	CREATE_MULTIPIECE_SHIPMENTS,
	createElementId,
	EXPRESS,
	FORM_CONTROL,
	MENU_ITEM,
	PARCEL_COLLECTION,
	PICK_UP_OPTION,
	PICK_UP_TIME_SLOT,
	SELECT
} from '@constants/id';
import { theme } from '@util/theme';

type FormValues = {
	timeSlot: number;
	pickupSelected: number;
};

const pickupTypeSelectValueMapping: Record<number, PickupType> = {
	1: 'PICKUP_REQUIRED',
	7: 'SENT_TO_TUNL',
	0: 'SCHEDULED_AT_LOCATION'
};

export function PickUp() {
	const { state, dispatch } = useShipmentContext();
	const { today, tomorrow, monday } = {
		...(state.rates.selectedService.carrier === 'FEDEX_INTERNATIONAL_PRIORITY'
			? pickupDateFedex()
			: pickupDateUPS())
	};
	const { control, watch, setValue, getValues, trigger } = useForm<FormValues>({
		defaultValues: {
			pickupSelected: 1,
			timeSlot: 0
		}
	});

	function handlePickUpChange(event: SelectChangeEvent<number>) {
		setValue('pickupSelected', Number(event.target.value));
		setValue('timeSlot', 0);

		// Check pickupTypeSelectValueMapping for the mapping of these numeric values
		if (getValues('timeSlot') === 0 && Number(event.target.value) === 1) {
			dispatch({
				type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
				payload: {
					sectionName: 'pickUp',
					complete: false
				}
			});
			return;
		}

		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'pickUp',
				complete: true
			}
		});

		let slot = 0;

		if (Number(event.target.value) === 7) {
			slot = 7;
		}

		dispatch({
			type: ShipmentActionTypes.UPDATE_PICKUP,
			payload: {
				slot: slot,
				pickupSelected: Number(event.target.value),
				reference: '',
				minDatetime: '',
				maxDatetime: '',
				isAccountAddress: false,
				pickupType: pickupTypeSelectValueMapping[Number(event.target.value)]
			}
		});
	}

	function handleTimeSlotChange(event: SelectChangeEvent<number>) {
		setValue('timeSlot', Number(event.target.value));

		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'pickUp',
				complete: true
			}
		});

		const slot = getValues('timeSlot');
		dispatch({
			type: ShipmentActionTypes.UPDATE_PICKUP,
			payload: {
				slot,
				pickupSelected: getValues('pickupSelected'),
				reference: '',
				minDatetime: getMinDateTime(slot, today, tomorrow, monday),
				maxDatetime: getMaxDateTime(slot, today, tomorrow, monday),
				isAccountAddress: false,
				pickupType: pickupTypeSelectValueMapping[getValues('pickupSelected')]
			}
		});
	}

	useEffect(() => {
		trigger('timeSlot');
	}, [state.rates.selectedService]);

	return (
		<Card sx={{ mb: 1 }}>
			<CardHeader
				title="Pickup/Drop-off"
				sx={{ backgroundColor: theme.palette.grey[100] }}
				titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
				avatar={
					state.formSections.pickUp.complete ? (
						<CheckCircleIcon sx={{ color: 'green' }} />
					) : (
						<CheckCircleOutlineIcon sx={{ color: 'text.secondary' }} />
					)
				}
			/>
			<CardContent>
				<Grid container size={12} spacing={2}>
					<Grid size={12}>
						<Controller
							control={control}
							name="pickupSelected"
							render={({ field: { value } }) => (
								<FormControl
									id={createElementId([
										CREATE_MULTIPIECE_SHIPMENTS,
										CHECKOUT,
										EXPRESS,
										PARCEL_COLLECTION,
										FORM_CONTROL,
										PICK_UP_OPTION
									])}
									variant={'standard'}
									fullWidth
								>
									<Select
										id={createElementId([
											CREATE_MULTIPIECE_SHIPMENTS,
											CHECKOUT,
											EXPRESS,
											PARCEL_COLLECTION,
											FORM_CONTROL,
											SELECT,
											PICK_UP_OPTION
										])}
										name="pickupSelected"
										value={value}
										onChange={handlePickUpChange}
										input={<BootstrapInput error={false} />}
										fullWidth
									>
										<MenuItem
											id={createElementId([
												CREATE_MULTIPIECE_SHIPMENTS,
												CHECKOUT,
												EXPRESS,
												PARCEL_COLLECTION,
												FORM_CONTROL,
												SELECT,
												PICK_UP_OPTION,
												MENU_ITEM,
												'yes'
											])}
											value={1}
										>
											Yes
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MULTIPIECE_SHIPMENTS,
												CHECKOUT,
												EXPRESS,
												PARCEL_COLLECTION,
												FORM_CONTROL,
												SELECT,
												PICK_UP_OPTION,
												MENU_ITEM,
												'no_tunl_pick_up'
											])}
											value={7}
										>
											No: I am sending my parcel to TUNL for pick up
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MULTIPIECE_SHIPMENTS,
												CHECKOUT,
												EXPRESS,
												PARCEL_COLLECTION,
												FORM_CONTROL,
												SELECT,
												PICK_UP_OPTION,
												MENU_ITEM,
												'no_my_loc_pick_up'
											])}
											value={0}
										>
											No: I already have a pick up scheduled at my location
										</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid size={12}>
						{watch('pickupSelected') === 1 && (
							<Controller
								control={control}
								name="timeSlot"
								render={({ field: { value } }) => (
									<FormControl
										id={createElementId([
											CREATE_MULTIPIECE_SHIPMENTS,
											CHECKOUT,
											EXPRESS,
											PARCEL_COLLECTION,
											FORM_CONTROL,
											PICK_UP_TIME_SLOT
										])}
										variant={'standard'}
										error
										fullWidth
									>
										<Select
											id={createElementId([
												CREATE_MULTIPIECE_SHIPMENTS,
												CHECKOUT,
												EXPRESS,
												PARCEL_COLLECTION,
												FORM_CONTROL,
												SELECT,
												PICK_UP_TIME_SLOT
											])}
											name="timeSlot"
											disabled={
												watch('pickupSelected') === 0 || watch('pickupSelected') === 7
													? true
													: false
											}
											value={value}
											onChange={handleTimeSlotChange}
											input={
												<BootstrapInput
													error={watch('pickupSelected') === 1 && watch('timeSlot') === 0}
												/>
											}
											fullWidth
										>
											<MenuItem
												id={createElementId([
													CREATE_MULTIPIECE_SHIPMENTS,
													CHECKOUT,
													EXPRESS,
													PARCEL_COLLECTION,
													FORM_CONTROL,
													SELECT,
													PICK_UP_TIME_SLOT,
													MENU_ITEM,
													'default'
												])}
												disabled
												value={0}
											>
												<em>Please Select Timeslot</em>
											</MenuItem>
											{today?.morning && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'today_morning'
													])}
													value={1}
												>
													Today (Morning)
												</MenuItem>
											)}
											{today?.afternoon && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'today_afternoon'
													])}
													value={2}
												>
													Today (Afternoon)
												</MenuItem>
											)}
											{tomorrow?.morning && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'tomorrow_morning'
													])}
													value={3}
												>
													Tomorrow (Morning)
												</MenuItem>
											)}
											{tomorrow?.afternoon && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'tomorrow_morning'
													])}
													value={4}
												>
													Tomorrow (Afternoon)
												</MenuItem>
											)}
											{monday?.morning && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'monday_morning'
													])}
													value={5}
												>
													Monday (Morning)
												</MenuItem>
											)}
											{monday?.afternoon && (
												<MenuItem
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT,
														MENU_ITEM,
														'monday_afternoon'
													])}
													value={6}
												>
													Monday (Afternoon)
												</MenuItem>
											)}
										</Select>
									</FormControl>
								)}
							/>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
