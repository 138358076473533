import {
	AccountCircle,
	Add,
	FitnessCenter,
	Flight,
	Handyman,
	Home,
	HouseSharp,
	Inventory,
	LibraryAdd,
	Logout,
	MenuBook,
	Storefront,
	SupervisedUserCircle
} from '@mui/icons-material';
import PinDropIcon from '@mui/icons-material/PinDrop';

import {
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Toolbar
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { toLower } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import TEXT from '@constants/text';
import { BUTTON, createElementId, LIST_ITEM, SIDEMENU } from '@constants/id';

export const drawerWidth = 210;

interface SidebarProps {
	open: boolean;
	onOpen: () => void;
	onClose: () => void;
	isXsSmMd: boolean;
	signUserOut: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ open, onOpen, onClose, isXsSmMd, signUserOut }) => {
	const navigate = useNavigate();
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const postHogBatchShipmentFlagEnabled = useFeatureFlagEnabled('batch-shipments-ft');
	const multiPieceShipmentFlagEnabled = useFeatureFlagEnabled('multi-piece-shipments-ft');
	const batchShipmentFlagEnabled =
		process.env.REACT_APP_ENVIRONMENT === 'prod' ? postHogBatchShipmentFlagEnabled : true;

	const location = useLocation();

	const showAdminLinks = () => {
		return user && user.admin_access;
	};

	const menuItemClick = (type: string, path: string) => {
		if (type === 'link') {
			navigate(path);
		} else if (type === 'external') {
			window.open(path, '_blank');
		} else if (type === 'logout') {
			signUserOut();
		} else {
			throw new Error('Menu item has no type');
		}
	};

	const menuItems = [
		{ text: TEXT.DASHBOARD, icon: <Home />, path: '/', admin: false, type: 'link', showMenuItem: true },
		{
			text: TEXT.CUSTOMS_DASHBOARD,
			icon: <HouseSharp />,
			path: '/customs/dashboard',
			type: 'link',
			showMenuItem: showAdminLinks()
		},
		{
			text: TEXT.VIEW_SHIPMENTS,
			icon: <Flight />,
			path: '/customs/shipments',
			type: 'link',
			showMenuItem: showAdminLinks()
		},
		{
			text: TEXT.CREATE_NEW_BOOKING,
			icon: <MenuBook />,
			path: '/customs/booking/create',
			type: 'link',
			showMenuItem: showAdminLinks()
		},
		{
			text: TEXT.CREATE_SHIPMENT,
			icon: <Add />,
			path: '/shipment',
			type: 'link',
			showMenuItem: true
		},
		{
			text: TEXT.BULK_SHIPMENTS,
			icon: <FitnessCenter />,
			path: '/shipment/bulk',
			type: 'link',
			showMenuItem: batchShipmentFlagEnabled || showAdminLinks()
		},
		{
			text: TEXT.CREATE_MULTI_PIECE_SHIPMENT,
			icon: <LibraryAdd />,
			path: '/shipment/multi-piece',
			type: 'link',
			showMenuItem: multiPieceShipmentFlagEnabled || showAdminLinks()
		},
		{
			text: TEXT.PRODUCT_LIBRARY,
			icon: <Inventory />,
			path: '/products',
			type: 'link',
			showMenuItem: true
		},
		{
			text: TEXT.MERCHANT_PROFILE,
			icon: <AccountCircle />,
			path: '/profile',
			type: 'link',
			showMenuItem: true
		},
		{
			text: TEXT.MANAGE_USERS,
			icon: <SupervisedUserCircle />,
			path: '/users',
			type: 'link',
			showMenuItem: true
		},
		{
			text: TEXT.SALES_CHANNELS,
			icon: <Storefront />,
			path: '/live-rates',
			type: 'link',
			showMenuItem: true
		},
		{ text: TEXT.TRACKER, icon: <PinDropIcon />, path: '/tracker', admin: false, type: 'link', showMenuItem: true },
		{
			text: TEXT.TOOLS,
			icon: <Handyman />,
			path: 'https://www.tunl.to/tools?signedin=true',
			type: 'external',
			showMenuItem: true
		},
		{ text: TEXT.LOGOUT, icon: <Logout />, path: '', type: 'logout', showMenuItem: true }
	];

	return (
		<SwipeableDrawer
			variant={isXsSmMd ? 'persistent' : 'temporary'}
			open={open}
			onOpen={onOpen}
			onClose={onClose}
			sx={{
				width: drawerWidth,
				overflowY: 'auto'
			}}
		>
			<Toolbar />
			<List sx={{ width: drawerWidth }}>
				{menuItems
					.filter(item => item.showMenuItem === true)
					.map((item, index) => (
						<ListItem
							id={createElementId([SIDEMENU, LIST_ITEM, toLower(item.text).replace(' ', '-')])}
							key={index}
							disablePadding
						>
							<ListItemButton
								id={createElementId([SIDEMENU, BUTTON, toLower(item.text)])}
								component={Link}
								onClick={event => menuItemClick(item.type, item.path)}
								selected={location.pathname === item.path}
							>
								<ListItemIcon sx={{ minWidth: '35px' }}>{item.icon}</ListItemIcon>
								<ListItemText primary={item.text} />
							</ListItemButton>
						</ListItem>
					))}
			</List>
		</SwipeableDrawer>
	);
};
