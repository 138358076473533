import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { AddressData } from '../types/address';
import { CustomsInfoData, ParcelData } from '../types/consignment';
import { Pickup } from '../types/pickup';
import { RatesWithSelectedService } from '../types/rates';
import { ShipmentActions } from './actions';
import { shipmentReducer } from './reducer';
import { CurrencyExchangeRateResponse } from '@tunl-types/currency';
import { currencyExchangeRateDefault } from '../components/Parcel/ParcelItems';
import { customsInfo, parcels } from '../components/Parcel/ParcelContainer';

type FormSectionState = {
	complete: boolean;
};

export type FormSections = {
	fromAddress: FormSectionState;
	toAddress: FormSectionState;
	services: FormSectionState;
	parcels: FormSectionState;
	pickUp: FormSectionState;
};

export type TriggerSections = {
	fetchRates: boolean;
	validAddress: boolean;
	validParcelDimensions: boolean;
	validCustomsItems: boolean;
	updatingCustomsItem: boolean;
};

export type State = {
	formSections: FormSections;
	fromAddress: AddressData;
	toAddress: AddressData;
	triggers: TriggerSections;
	rates: RatesWithSelectedService;
	parcels: ParcelData[];
	customsInfo: CustomsInfoData;
	currencyExchangeRate: CurrencyExchangeRateResponse;
	customsTotal: number;
	pickup: Pickup;
};

const initialState: State = {
	formSections: {
		fromAddress: {
			complete: false
		},
		toAddress: {
			complete: false
		},
		services: {
			complete: false
		},
		parcels: {
			complete: false
		},
		pickUp: {
			complete: false
		}
	},
	triggers: {
		fetchRates: false,
		validAddress: false,
		validParcelDimensions: false,
		validCustomsItems: false,
		updatingCustomsItem: false
	},
	fromAddress: {
		name: '',
		company: '',
		email: '',
		phone: '',
		street1: '',
		street2: '',
		zipPostal: '',
		cityLocality: '',
		stateProvince: '',
		country: '',
		plusCode: ''
	},
	toAddress: {
		name: '',
		company: '',
		email: '',
		phone: '',
		street1: '',
		street2: '',
		zipPostal: '',
		cityLocality: '',
		stateProvince: '',
		country: '',
		plusCode: ''
	},
	rates: {
		selectedService: {
			carrier: '',
			partnerService: '',
			deliveryEstimate: '',
			serviceOptions: '',
			image: '',
			breakdown: [],
			subtotals: {
				fuelSurcharge: 0,
				insuranceCost: 0,
				otherSurcharge: 0,
				shipping: 0
			},
			source: '',
			sourceRemoteId: null,
			service: '',
			rate: 0,
			currency: '',
			carrierAccountId: ''
		},
		returnedRates: [
			{
				carrier: '',
				partnerService: '',
				deliveryEstimate: '',
				serviceOptions: '',
				image: '',
				breakdown: [],
				subtotals: {
					fuelSurcharge: 0,
					insuranceCost: 0,
					otherSurcharge: 0,
					shipping: 0
				},
				source: '',
				sourceRemoteId: null,
				service: '',
				rate: 0,
				currency: '',
				carrierAccountId: ''
			}
		]
	},
	parcels: parcels,
	customsInfo: customsInfo,
	currencyExchangeRate: currencyExchangeRateDefault,
	customsTotal: 0,
	pickup: {
		slot: 0,
		pickupSelected: 0,
		reference: '',
		minDatetime: '',
		maxDatetime: '',
		isAccountAddress: false,
		pickupType: 'PICKUP_REQUIRED'
	}
};

export type ShipmentContextState = {
	state: State;
	dispatch: Dispatch<ShipmentActions>;
};

export const ShipmentContext = createContext<ShipmentContextState | null>(null);

type ContextProviderProps = {
	children: ReactNode;
};

export function ShipmentProvider({ children }: ContextProviderProps): JSX.Element {
	const [state, dispatch] = useReducer(shipmentReducer, initialState);

	return <ShipmentContext.Provider value={{ state, dispatch }}>{children}</ShipmentContext.Provider>;
}
