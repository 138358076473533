import {
	Alert,
	AlertTitle,
	Button,
	Card,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid2 as Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from '@mui/material';
import countryToCurrency, { Countries } from 'country-to-currency';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMerchant } from '../../../../context/MerchantContext';
import { useShipmentContext } from '../../hooks/useShipmentContext';
import { AddressData } from '../../types/address';
import { CustomsInfoData, ParcelData } from '../../types/consignment';
import { AmountSubtotals, DutiesAndTaxesCalculation, DutiesAndTaxesData } from '../../types/dutiesAndTaxes';
import { ConsignmentRate } from '../../types/rates';
import { formatAmount, formatNumberTwoDecimals } from '../../utils/utils';
import { BootstrapInput } from '../BootstrapInput';
import { CenteredLoader } from '../CenteredLoader';
import { CostBreakDownItem } from './CostBreakDownItem';
import { DutiesAndTaxesBreakDown } from './DutiesAndTaxesBreakDown';
import { ServiceInformation } from './ServiceInformation';
import { axiosConfig } from '@constants/axios';
import {
	CHECKOUT,
	CREATE_MULTIPIECE_SHIPMENTS,
	createElementId,
	DFT,
	EXPRESS,
	SELECT,
	SHIPMENT_PROTECTION,
	TEXTFIELD
} from '@constants/id';

interface Props {
	savingShipment: (saving: boolean) => void;
}

type Incorterm = 'DDP' | 'DAP';

type FormValues = {
	requestSignature: boolean;
	shipmentProtection: boolean;
	protectionValue: number;
	incoterm: Incorterm;
	termsOfService: boolean;
};
interface ShipmentInput {
	fromAddress: AddressData;
	toAddress: AddressData;
	parcels: ParcelData[];
	shipmentType: 'EXPRESS' | 'ECONOMY';
	rate: number;
	merchantId: string;
	customsIncoterm: string;
	reference: string;
	service: string;
	deliveryConfirmation: boolean;
	pickupType: string;
	protectionValue: number;
	customsInfo: CustomsInfoData;
	pickup: pickupInput;
	ratesBreakdown: {
		returnedRates: ConsignmentRate[];
		ddp: ddp | null;
	};
	contentsSummary: ContentsSummary;
}
interface TotalProductValue {
	baseCurrency: string;
	baseValue: number;
	exchangeRate: number;
	zarValue: number;
}
interface ContentsSummary {
	totalProductValue: TotalProductValue;
}

interface ddp {
	duties: DutiesAndTaxesCalculation[] | undefined;
	fees: DutiesAndTaxesCalculation[] | undefined;
	taxes: DutiesAndTaxesCalculation[] | undefined;
	amountSubtotalsBase: AmountSubtotals | undefined;
	landedCostId: string;
	baseCurrency: string;
	landedCostsTotalBase: number;
	landedCostsTotalRands: number;
}

interface pickupInput {
	slot: number;
	pickupSelected: number;
	reference: string;
	minDatetime: string;
	maxDatetime: string;
	isAccountAddress: boolean;
}
interface CreationError {
	title: string;
	message: string;
}

export function BuyLabel({ savingShipment }: Props) {
	const { merchantProfile } = useMerchant();
	const { state } = useShipmentContext();
	const navigate = useNavigate();
	const { control, watch, setValue, getValues } = useForm<FormValues>({
		defaultValues: {
			requestSignature: false,
			shipmentProtection: true,
			protectionValue: state.customsTotal,
			incoterm: merchantProfile.merchant_settings.ddp ? 'DDP' : 'DAP',
			termsOfService: false
		}
	});

	const [dutiesAndTaxesBreakdownOpen, setDutiesAndTaxesBreakdownOpen] = useState<boolean>(false);
	const [creationError, setCreationError] = useState<CreationError | null>(null);

	const [dutiesAndTaxes, setDutiesAndTaxes] = useState<DutiesAndTaxesData | null>(null);
	const [dutiesAndTaxesError, setDutiesAndTaxesError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const axiosInstance = axiosConfig();

	useEffect(() => {
		if (!!state.currencyExchangeRate) {
			const calculatedProtectionValue = state.currencyExchangeRate.rate * state.customsTotal;
			const formattedProtectionValue = formatNumberTwoDecimals(calculatedProtectionValue);
			setValue('protectionValue', formattedProtectionValue);
		}
	}, [state.currencyExchangeRate.rate, state.customsTotal]);

	function calculateProtectionValue() {
		if (!getValues('shipmentProtection')) {
			return 0;
		}
		const protectionTotal = getValues('protectionValue');

		return protectionTotal < 2001 ? 50 : protectionTotal * 0.025;
	}

	async function buyLabelForShipment() {
		savingShipment(true);
		const rate = state.rates.selectedService.rate;

		const shipmentInput: ShipmentInput = {
			toAddress: state.toAddress,
			fromAddress: state.fromAddress,
			parcels: state.parcels,
			customsInfo: state.customsInfo,
			pickup: state.pickup,
			shipmentType: state.rates.selectedService.partnerService === 'GROUND' ? 'ECONOMY' : 'EXPRESS',
			service: state.rates.selectedService.partnerService,
			rate: rate,
			merchantId: merchantProfile._id as string,
			customsIncoterm: getValues('incoterm'),
			deliveryConfirmation: getValues('requestSignature'),
			reference: state.customsInfo.invoiceNumber,
			pickupType: state.pickup.pickupType,
			protectionValue: getValues('shipmentProtection') ? getValues('protectionValue') : 0,
			contentsSummary: {
				totalProductValue: {
					baseCurrency: state.currencyExchangeRate.source_currency ?? 'ZAR',
					baseValue: state.customsTotal,
					exchangeRate: state.currencyExchangeRate.rate,
					zarValue: state.currencyExchangeRate.rate * state.customsTotal
				}
			},
			ratesBreakdown: {
				returnedRates: state.rates.returnedRates,
				ddp: {
					duties: dutiesAndTaxes?.duties,
					fees: dutiesAndTaxes?.fees,
					taxes: dutiesAndTaxes?.taxes,
					amountSubtotalsBase: dutiesAndTaxes?.amountSubtotals,
					baseCurrency: 'ZAR',
					landedCostsTotalBase: dutiesAndTaxes?.amountSubtotals.landedCostTotal as number,
					landedCostsTotalRands: dutiesAndTaxes?.amountSubtotals.landedCostTotal as number,
					landedCostId: dutiesAndTaxes?.id as string
				}
			}
		};

		await axiosInstance
			.post('/api/v1/shipments', shipmentInput)
			.then(response => {
				const shipment = response.data.data;
				savingShipment(false);
				posthog.capture('shipment_created', {
					shipment_id: shipment?.shipmentId,
					rate_charged: shipment?.rateCharged,
					ddp: {
						duties: dutiesAndTaxes?.duties,
						fees: dutiesAndTaxes?.fees,
						taxes: dutiesAndTaxes?.taxes,
						amountSubtotalsBase: dutiesAndTaxes?.amountSubtotals,
						baseCurrency: 'ZAR',
						landedCostsTotalBase: dutiesAndTaxes?.amountSubtotals.landedCostTotal as number,
						landedCostsTotalRands: dutiesAndTaxes?.amountSubtotals.landedCostTotal as number,
						landedCostId: dutiesAndTaxes?.id as string
					},
					insurance: shipment?.insurance,
					carrier_track_code: shipment?.carrierTrackCode,
					carrier: state.rates.selectedService.partnerService,
					carrier_service: state.rates.selectedService.carrier,
					total_chargable_weight: shipment?.totalChargeableWeight,
					total_customs_value: state.customsTotal,
					customsIncoterm: getValues('incoterm')
				});
				navigate(`/shipments/${shipment.id}?new=true`);
			})
			.catch(error => {
				if (error?.response?.data?.data?.errors) {
					setCreationError({
						title: 'Could not create shipment',
						message: error?.response?.data?.data?.errors.map((error: string) => {
							return `${error}. \n`;
						})
					});
				} else {
					setCreationError({
						title: 'Error creating shipment',
						message: 'Please review your shipment and try again'
					});
				}
				setValue('termsOfService', false);
				savingShipment(false);
			});
	}

	async function calculateDutiesAndTaxes() {
		setDutiesAndTaxesError(false);
		setIsLoading(true);
		try {
			const dutiesAndTaxesData = {
				origin: {
					country: state.fromAddress.country,
					postalCode: state.fromAddress.zipPostal,
					locality: state.fromAddress.cityLocality,
					line1: state.fromAddress.street1,
					line2: state.fromAddress.street2,
					firstName: state.fromAddress.name,
					// TODO: need to remove
					lastName: 'test',
					phone: state.fromAddress.phone,
					email: state.fromAddress.email
				},
				destination: {
					country: state.toAddress.country,
					postalCode: state.toAddress.zipPostal,
					locality: state.toAddress.cityLocality,
					line1: state.toAddress.street1,
					line2: state.toAddress.street2,
					firstName: state.toAddress.name,
					// TODO: need to remove
					lastName: 'test',
					phone: state.toAddress.phone,
					email: state.toAddress.email
				},
				customsItems: state.parcels
					.map(parcel => {
						return parcel.customsItems.map(ci => {
							return {
								amount: ci.value,
								countryOfOrigin: ci.originCountry,
								hsCode: ci.hsTariffNumber,
								quantity: ci.quantity,
								currencyCode: ci.currency
							};
						});
					})
					.flat(),
				incoterm: getValues('incoterm'),
				serviceLevelCode: state.rates.selectedService.partnerService,
				destinationCurrency: countryToCurrency[state.toAddress.country as Countries]
			};
			const response = await axiosInstance.post('api/v1/rates/duties-and-taxes', dutiesAndTaxesData);
			setDutiesAndTaxes(response.data.data as DutiesAndTaxesData);
			setIsLoading(false);
		} catch (error) {
			setDutiesAndTaxesError(true);
			setIsLoading(false);
			console.log('Error : ', error);
		}
	}

	function handleShowDutiesAndTaxesBreakDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		setDutiesAndTaxesBreakdownOpen(!dutiesAndTaxesBreakdownOpen);
	}

	function handleshipmentProtectionCheck(event: React.ChangeEvent<HTMLInputElement>) {
		setValue('shipmentProtection', event.target.checked);
		setValue('protectionValue', getValues('protectionValue'));
	}

	function canBuyShipment(): boolean {
		return (
			watch('termsOfService') &&
			state.formSections.fromAddress.complete &&
			state.formSections.toAddress.complete &&
			state.formSections.parcels.complete &&
			state.formSections.services.complete &&
			state.formSections.pickUp.complete
		);
	}

	function handleIncotermSelectChange(event: SelectChangeEvent<string>) {
		setValue('incoterm', event.target.value as Incorterm);
		calculateDutiesAndTaxes();
	}

	useEffect(() => {
		if (
			state.formSections.services.complete &&
			state.formSections.parcels.complete &&
			state.triggers.validCustomsItems
		) {
			calculateDutiesAndTaxes();
		}
	}, [
		state.formSections.parcels.complete,
		state.formSections.services.complete,
		state.triggers.validCustomsItems,
		state.triggers.updatingCustomsItem
	]);

	return (
		<Grid container size={12} spacing={2} marginBottom={10}>
			<Grid size={12}>
				<Card variant="outlined">
					<Grid container size={12} sx={{ pt: 1 }}>
						{state.formSections.services.complete && (
							<ServiceInformation service={state.rates.selectedService} />
						)}
					</Grid>
					<Divider />
					<Grid container size={12} padding={2}>
						<Grid container size={12}>
							<Typography variant="subtitle1" fontWeight="bold">
								Service Options
							</Typography>
						</Grid>
						<Grid container size={12}>
							<Controller
								control={control}
								name="requestSignature"
								render={({ field: { onChange, value } }) => (
									<FormControlLabel
										control={<Checkbox onChange={onChange} value={value} />}
										label="Request signature on receipt?"
									/>
								)}
							/>
						</Grid>
						<Grid container size={12}>
							<Grid size={12}>
								<Controller
									control={control}
									name="shipmentProtection"
									render={({ field: { value } }) => (
										<FormControlLabel
											control={
												<Checkbox
													defaultChecked={true}
													value={value}
													onChange={handleshipmentProtectionCheck}
												/>
											}
											label="Add shipment protection?"
										/>
									)}
								/>
							</Grid>
							{watch('shipmentProtection') && (
								<Grid size={12}>
									<Controller
										control={control}
										name="protectionValue"
										render={({ field: { onChange, value } }) => (
											<TextField
												fullWidth
												size="small"
												id={createElementId([
													CREATE_MULTIPIECE_SHIPMENTS,
													SHIPMENT_PROTECTION,
													TEXTFIELD,
													'protection_value'
												])}
												type="number"
												label="Protection Value (R)"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>
						<Grid container size={12} marginTop={1}>
							<Grid size={12}>
								<Typography variant="subtitle1">How would you like to pay duties and taxes?</Typography>
							</Grid>
							<Grid size={12}>
								<Controller
									control={control}
									name="incoterm"
									render={({ field: { value } }) => (
										<Select
											id={createElementId([
												CREATE_MULTIPIECE_SHIPMENTS,
												CHECKOUT,
												EXPRESS,
												DFT,
												SELECT
											])}
											disabled={!state.formSections.parcels.complete}
											fullWidth
											value={value}
											onChange={handleIncotermSelectChange}
											input={<BootstrapInput error={false} />}
										>
											<MenuItem
												id={createElementId([
													CREATE_MULTIPIECE_SHIPMENTS,
													CHECKOUT,
													EXPRESS,
													DFT,
													SELECT,
													'DAP'
												])}
												value={'DAP'}
											>
												Bill the Receiver (DAP)
											</MenuItem>
											<MenuItem
												id={createElementId([
													CREATE_MULTIPIECE_SHIPMENTS,
													CHECKOUT,
													EXPRESS,
													DFT,
													SELECT,
													'DDP'
												])}
												value={'DDP'}
											>
												Bill my account (DDP)
											</MenuItem>
										</Select>
									)}
								/>
							</Grid>
							{isLoading && <CenteredLoader />}
							{dutiesAndTaxesError ? (
								<Grid size={12} paddingTop={2} paddingBottom={2}>
									<Alert
										variant="outlined"
										severity="error"
										action={
											<Button color="inherit" size="small" onClick={calculateDutiesAndTaxes}>
												retry
											</Button>
										}
									>
										An error occured while fetching duties and taxes
									</Alert>
								</Grid>
							) : (
								<>
									{state.formSections.parcels.complete ? (
										<Grid size={12} paddingTop={2} paddingBottom={2}>
											<Typography
												variant="body1"
												sx={{ color: '#007bc4', cursor: 'pointer' }}
												onClick={handleShowDutiesAndTaxesBreakDown}
												component={'div'}
											>
												{dutiesAndTaxesBreakdownOpen ? `Hide` : 'Show'} duties and taxes
												breakdown
											</Typography>
											{dutiesAndTaxes && (
												<DutiesAndTaxesBreakDown
													open={dutiesAndTaxesBreakdownOpen}
													amountSubTotals={dutiesAndTaxes.amountSubtotals}
													duties={dutiesAndTaxes?.duties}
													taxes={dutiesAndTaxes?.taxes}
													fees={dutiesAndTaxes?.fees}
												/>
											)}
										</Grid>
									) : (
										<Grid size={12} paddingTop={2} paddingBottom={2}>
											<Alert variant="outlined" severity="warning">
												Add items together with their dimensions to your parcels in order to
												calculate duties and taxes.
											</Alert>
										</Grid>
									)}
								</>
							)}
						</Grid>
					</Grid>
					<Divider />
					<Grid container size={12} padding={2}>
						<CostBreakDownItem
							label="Shipping"
							value={`R${formatAmount(state.rates.selectedService.rate)}`}
						/>
						<CostBreakDownItem
							label="Protection Cost"
							value={`R${formatAmount(calculateProtectionValue())}`}
						/>
						<CostBreakDownItem
							label="Signature Required"
							value={`R${watch('requestSignature') ? formatAmount(50) : formatAmount(0)}`}
						/>
						<CostBreakDownItem
							label="Duties and Taxes"
							value={
								dutiesAndTaxes && watch('incoterm') !== 'DAP'
									? `R${formatAmount(dutiesAndTaxes?.amountSubtotals.landedCostTotal)}`
									: `R${formatAmount(0)}`
							}
						/>
					</Grid>
					<Divider />
					<Grid container size={12} padding={2}>
						<Grid size={6}>
							<Typography variant="h5" fontWeight="bold">
								Total
							</Typography>
						</Grid>
						<Grid size={6} textAlign="end">
							<Typography variant="h5" fontWeight="bold">
								{`R${formatAmount(
									Number(state.rates.selectedService.rate) +
										Number(calculateProtectionValue()) +
										Number(watch('requestSignature') ? 50 : 0) +
										Number(
											dutiesAndTaxes && watch('incoterm') !== 'DAP'
												? dutiesAndTaxes?.amountSubtotals.landedCostTotal
												: 0
										)
								)}`}
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid container size={12} padding={1}>
						<Controller
							control={control}
							name="termsOfService"
							render={({ field: { onChange, value, name } }) => (
								<FormControlLabel
									control={<Checkbox value={value} checked={value} onChange={onChange} />}
									label={
										<span>
											I hereby certify that the information provided is true and correct and that
											the contents and value of this shipment are as stated above.{' '}
											<a href="https://www.tunl.to/terms" rel="noreferrer" target="_blank">
												Terms of Service
											</a>
										</span>
									}
								/>
							)}
						/>
					</Grid>
				</Card>
			</Grid>
			<Grid container size={12}>
				{creationError && (
					<Alert sx={{ width: '100%', whiteSpace: 'pre-wrap' }} severity="error">
						<AlertTitle>{creationError.title}</AlertTitle>
						{creationError.message}
					</Alert>
				)}

				<Button
					size="large"
					sx={{
						backgroundColor: '#03C988',
						':hover': {
							backgroundColor: '#057852'
						},
						whiteSpace: 'nowrap'
					}}
					disabled={!canBuyShipment()}
					onClick={buyLabelForShipment}
					variant="contained"
					fullWidth
				>
					Buy Label(s)
				</Button>
			</Grid>
		</Grid>
	);
}
