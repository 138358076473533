import { Backdrop, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import posthog from 'posthog-js';
import { useRef, useState } from 'react';
import { AddressForm } from './components/AddressForm';
import { BuyLabel } from './components/BuyLabel/BuyLabel';
import { FormSection } from './components/FormSection';
import ParcelContainer from './components/Parcel/ParcelContainer';
import { PickUp } from './components/PickUp';
import Services from './components/Services';
import { AddressData } from './types/address';
import { useMerchant } from '@context/MerchantContext';
import { ShipmentContext, ShipmentProvider } from './context/ShipmentContext';
import { CREATE_MULTIPIECE_SHIPMENTS, createElementId, HEADING } from '@constants/id';
import { formatAddressForSectionSummary } from './utils/utils';
import { theme } from '@util/theme';

interface Props {}

export function MultiPieceShipment({}: Props) {
	const servicesCompleteRef = useRef<boolean>(false);
	const { merchantProfile, userProfile } = useMerchant();
	const [savingShipment, setSavingShipment] = useState<boolean>(false);
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const containerStyle = isXsSmMd
		? {
				position: 'sticky',
				top: '65px'
		  }
		: {};

	posthog.capture('create_shipment_started', {
		user: userProfile.user_email,
		merchant: merchantProfile.merchant_name
	});

	return (
		<ShipmentProvider>
			<Grid
				container
				direction="row"
				alignContent="center"
				justifyContent="center"
				spacing={2}
				sx={{ marginLeft: 2, marginRight: 2 }}
			>
				<Grid size={12} sx={{ pl: 1, pt: 1 }}>
					<Typography id={createElementId([CREATE_MULTIPIECE_SHIPMENTS, HEADING])} variant="h6">
						Create multi-parcel shipment
					</Typography>
				</Grid>
				<ShipmentContext.Consumer>
					{value => {
						if (value?.state.formSections.services.complete) {
							servicesCompleteRef.current = true;
						}
						return (
							<>
								<Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
									<Grid sx={{ ...containerStyle }}>
										<FormSection
											id="ship_from"
											title="Ship From"
											summary={formatAddressForSectionSummary(
												value?.state.fromAddress as AddressData
											)}
											expanded={false}
											complete={!!value?.state.formSections.fromAddress.complete}
										>
											<AddressForm address_type="shipFrom" />
										</FormSection>
										<FormSection
											id="ship_to"
											title="Ship To"
											summary={formatAddressForSectionSummary(
												value?.state.toAddress as AddressData,
												!!value?.state.formSections.toAddress.complete
											)}
											expanded={true}
											complete={!!value?.state.formSections.toAddress.complete}
										>
											<AddressForm address_type="shipTo" />
										</FormSection>
										<FormSection
											id="parcels"
											title="Parcel details"
											summary={''}
											expanded={true}
											complete={!!value?.state.formSections.parcels.complete}
										>
											<ParcelContainer />
										</FormSection>
									</Grid>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
									<Grid sx={{ ...containerStyle }}>
										<Services />
										{servicesCompleteRef.current && <PickUp />}
										{servicesCompleteRef.current && <BuyLabel savingShipment={setSavingShipment} />}
									</Grid>
									<Backdrop
										sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
										open={savingShipment}
									>
										<Grid container spacing={2}>
											<Grid
												size={12}
												display="flex"
												justifyContent="center"
												alignContent="center"
											>
												<CircularProgress color="inherit" />
											</Grid>
											<Grid
												size={12}
												display="flex"
												justifyContent="center"
												alignContent="center"
											>
												<Typography variant="h5">
													Creating a shipment, please wait a moment.
												</Typography>
											</Grid>
										</Grid>
									</Backdrop>
								</Grid>
							</>
						);
					}}
				</ShipmentContext.Consumer>
			</Grid>
		</ShipmentProvider>
	);
}
