import { MRT_PaginationState } from 'material-react-table';
import { Dispatch, ReactNode, createContext, useReducer } from 'react';
import { MerchantDashboardActions } from './actions';
import { merchantDashboardReducer } from './reducer';
import { Shipment } from '@tunl-types/shipment';

export type MerchantDashboardState = {
	loading: boolean;
	shipmentList: Array<Shipment> | null;
	rowCount: number | null;
	pagination: MRT_PaginationState;
	error: boolean;
};

const initialState: MerchantDashboardState = {
	loading: true,
	shipmentList: null,
	rowCount: null,
	pagination: { pageIndex: 0, pageSize: 10 },
	error: false
};

export type MerchantDashboardContextState = {
	state: MerchantDashboardState;
	dispatch: Dispatch<MerchantDashboardActions>;
};

export const MerchantDashboardContext = createContext<MerchantDashboardContextState | null>(null);

type ContextProviderProps = {
	children: ReactNode;
};

export function MerchantDashboardProvider({ children }: ContextProviderProps): JSX.Element {
	const [state, dispatch] = useReducer(merchantDashboardReducer, initialState);

	return (
		<MerchantDashboardContext.Provider value={{ state, dispatch }}>{children}</MerchantDashboardContext.Provider>
	);
}
