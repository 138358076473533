import { Grid2 as Grid, IconButton, Popover, Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ConsignmentRate } from '../../types/rates';
import { useState } from 'react';
import { formatAmount } from '@pages/Shipments/utils/utils';

interface Props {
	service: ConsignmentRate;
}

export function ServiceInformation({ service }: Props) {
	const { carrier, deliveryEstimate, rate, breakdown, subtotals, image } = service;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	function rateHasSurcharges() {
		return (breakdown || []).some(breakdown => breakdown.type == 'SURCHARGE');
	}

	return (
		<Grid container size={12}>
			<Grid size={3} sx={{ px: 1 }} alignContent="center" justifyContent="center">
				<Box
					component="img"
					sx={{
						width: '100%'
					}}
					src={`/img/${image}`}
				/>
			</Grid>
			<Grid container size={6}>
				<Grid size={12} alignContent="center">
					<Typography variant="subtitle1" fontWeight="bold">
						{carrier}
					</Typography>
					<Typography variant="subtitle2">{deliveryEstimate}</Typography>
					{rateHasSurcharges() && (
						<>
							<Typography variant="subtitle2">
								Surcharges: R{subtotals.otherSurcharge}
								<IconButton
									aria-owns={open ? 'mouse-over-popover' : undefined}
									aria-haspopup="true"
									onMouseEnter={handlePopoverOpen}
									onMouseLeave={handlePopoverClose}
									size="small"
								>
									<InfoOutlinedIcon fontSize="small" />
								</IconButton>
							</Typography>
							<Popover
								id="mouse-over-popover"
								sx={{
									pointerEvents: 'none'
								}}
								open={open}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left'
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left'
								}}
								onClose={handlePopoverClose}
								disableRestoreFocus
							>
								<Typography variant="subtitle1" fontWeight="bold" flexWrap="wrap" sx={{ margin: 1 }}>
									The total price includes surcharges such as outlying area pickup/delivery fees.
								</Typography>
							</Popover>
						</>
					)}
				</Grid>
			</Grid>
			<Grid size={3} alignContent="center">
				<Typography variant="h6" fontWeight="bold">
					{`R${formatAmount(rate)}`}
				</Typography>
			</Grid>
		</Grid>
	);
}
