import { axiosConfig } from '@constants/axios';
import { createElementId, DROPDOWN, MENU_ITEM, SELECT, SHIPMENT_TABLE, TRACKING_STATUS } from '@constants/id';
import { Alert, CircularProgress, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Shipment } from '@tunl-types/shipment';
import { TrackingStatus } from '@tunl-types/tracker';
import { SnackAlert } from '@tunl-types/util';
import { useState } from 'react';

export interface EventStatusDropdownProps {
	shipment: Shipment;
}

export default function EventStatusDropwdown(props: EventStatusDropdownProps) {
	const { shipment } = props;
	const adminUser = JSON.parse(sessionStorage.getItem('user') || '{}');
	const axiosInstance = axiosConfig();
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({
		type: 'success',
		message: ''
	});
	const [eventStatus, setEventStatus] = useState<TrackingStatus>(
		shipment.event_status === 'TBD' ? TrackingStatus.PreTransit : (shipment.event_status as TrackingStatus)
	);
	const [loading, setLoading] = useState(false);

	const isEditable = [TrackingStatus.PendingCancel, TrackingStatus.InTransit, TrackingStatus.OutForDelivery].includes(
		eventStatus
	);

	const statuses = [
		{ value: TrackingStatus.PendingCancel, label: 'Pending Cancel' },
		{ value: TrackingStatus.PreTransit, label: 'Pre Transit' },
		{ value: TrackingStatus.InTransit, label: 'In Transit' },
		{ value: TrackingStatus.OutForDelivery, label: 'Out For Delivery' },
		{ value: TrackingStatus.Cancelled, label: 'Cancelled' },
		{ value: TrackingStatus.Delivered, label: 'Delivered' },
		{ value: TrackingStatus.ReturnToSender, label: 'Returned' },
		{ value: TrackingStatus.AvailableForPickUp, label: 'Available for Pickup' },
		{ value: TrackingStatus.Failure, label: 'Exception' }
	];

	const getStyles = (status: string | undefined) => {
		const statusColors: Record<string, { backgroundColor: string; color: string }> = {
			[TrackingStatus.PreTransit]: { backgroundColor: '#0652DD', color: '#fff' },
			[TrackingStatus.InTransit]: { backgroundColor: '#ff9f1a', color: '#000' },
			[TrackingStatus.OutForDelivery]: { backgroundColor: '#fff200', color: '#000' },
			[TrackingStatus.Cancelled]: { backgroundColor: 'red', color: '#fff' },
			[TrackingStatus.PendingCancel]: { backgroundColor: 'grey', color: '#fff' },
			[TrackingStatus.ReturnToSender]: { backgroundColor: '#ff9f1a', color: '#000' },
			[TrackingStatus.Failure]: { backgroundColor: '#ff9f1a', color: '#000' },
			[TrackingStatus.Delivered]: { backgroundColor: '#4cd137', color: '#000' },
			[TrackingStatus.AvailableForPickUp]: { backgroundColor: '#37d1ad', color: '#000' }
		};
		return statusColors[status || ''] || { backgroundColor: '#EA2027', color: '#000' };
	};

	const handleStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const newStatus = event.target.value as TrackingStatus;

		setEventStatus(newStatus);
		setLoading(true);

		try {
			const response = await axiosInstance.post('/shipment/updateShipmentEventStatus', {
				shipment_id: shipment.shipment_id,
				event_status: newStatus
			});

			setSnackAlert({
				type: response.status === 200 ? 'success' : 'error',
				message:
					response.status === 200
						? 'Shipment status updated successfully'
						: 'Failed to update shipment status'
			});
		} catch {
			setSnackAlert({ type: 'error', message: 'Failed to update shipment status' });
		} finally {
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	const handleAlertClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason !== 'clickaway') {
			setOpenSnackBar(false);
		}
	};

	return (
		<div>
			<Typography
				noWrap
				p={0.51}
				sx={{
					...getStyles(eventStatus),
					borderRadius: '5px',
					textAlign: 'center'
				}}
			>
				{loading ? (
					<CircularProgress color="inherit" size={14} sx={{ mt: '1vh' }} />
				) : isEditable && adminUser.admin_access ? (
					<TextField
						id={createElementId([SHIPMENT_TABLE, TRACKING_STATUS, SELECT, DROPDOWN, 'Event_Status'])}
						value={eventStatus}
						select
						onChange={handleStatusChange}
						variant="standard"
						fullWidth
						sx={{ height: '25px' }}
						InputProps={{
							style: { color: eventStatus === TrackingStatus.PendingCancel ? '#FFFFFF' : '#000000' }
						}}
					>
						{statuses.map(({ value, label }) => (
							<MenuItem
								key={value}
								id={createElementId([SHIPMENT_TABLE, TRACKING_STATUS, SELECT, MENU_ITEM, value])}
								value={value}
							>
								{label}
							</MenuItem>
						))}
					</TextField>
				) : (
					statuses.find(s => s.value === eventStatus)?.label || 'Unknown Status'
				)}
			</Typography>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
