import TEXT from '@constants/text';

export class CSVHeaderValidator {
	static setUSPostalCode = (postalCode: string) => {
		return postalCode?.padStart(5, '0');
	};

	static validatePostalCode = (country: string, postalCode: string) => {
		if (country?.toUpperCase() === 'US') {
			return CSVHeaderValidator.setUSPostalCode(postalCode);
		}
		return postalCode;
	};

	static csvHeaders = (actualHeaders: string[]) => {
		let errorMessage = '';

		// Validate base headers
		const missingBaseHeaders = TEXT.CSV_BASE_HEADERS.filter(header => !actualHeaders.includes(header));
		if (missingBaseHeaders.length > 0) {
			errorMessage += `Missing base headers: ${missingBaseHeaders.join(', ')}\n`;
		}

		// Validate dynamic headers (e.g., (Item Description)_1, (HS Code)_1)
		let dynamicCount = 1;

		while (true) {
			const dynamicHeaders = TEXT.CSV_DYNAMIC_HEADERS.map(header => `${header}_${dynamicCount}`);
			const missingDynamicHeaders = dynamicHeaders.filter(header => !actualHeaders.includes(header));

			if (missingDynamicHeaders.length === dynamicHeaders.length) {
				// Stop if no dynamic headers for this count exist, meaning we reached the end
				break;
			} else if (missingDynamicHeaders.length > 0) {
				errorMessage += `Missing dynamic headers for set ${dynamicCount}: ${missingDynamicHeaders.join(
					', '
				)}\n`;
			}
			dynamicCount++;
		}
		return errorMessage.trim();
	};
}
