import { Auth } from 'aws-amplify';
import axios from 'axios';

// export function useAuthToken() {
// 	const { user } = useAuthenticator((context) => [context.user]);
// 	const token = user?.getSignInUserSession()?.getIdToken().getJwtToken();
// 	return <string>token;
// };

export function axiosConfig() {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_BASEURL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf'
		}
	});

	instance.interceptors.request.use(
		async config => {
			const session = await Auth.currentSession(); // This will refresh the token if needed
			const token = session.getIdToken().getJwtToken();
			// Do something before the request is sent
			// For example, you can add an authorization token to headers
			if (config.headers) {
				config.headers.Authorization = `${token}`;
			}
			return config;
		},
		error => {
			// Do something with request error
			return Promise.reject(error);
		}
	);

	// Add a response interceptor
	instance.interceptors.response.use(
		response => {
			// Any status code that lies within the range of 2xx causes this function to trigger
			return response;
		},
		error => {
			// Any status codes that fall outside the range of 2xx cause this function to trigger
			if (error.response && error.response.status === 401) {
				// Handle unauthorized access
				// For example, you can redirect to a login page
			}
			return Promise.reject(error);
		}
	);

	return instance;
}
