import { Button } from '@mui/material';
import { shipmentCostTotal } from '@util/shipments';


export default function SnapScanPayNow(props?:any){
	const {shipment,shipmentProtection, landedCosts, deliveryConfirmationCost, multipieceShipment, buttonDisabled} = props;

	return (
		<Button
			sx={{
				backgroundColor: '#027b2c',
				':hover': {
					backgroundColor: '#019636'
				},
				whiteSpace: 'nowrap'
			}}
			disabled={buttonDisabled}
			color="primary"
			variant="contained"
			size="small"
			onClick={() => {
				const total = shipmentCostTotal(shipment);

				const snapScanTotal = Math.round(Number(total) * 100);

				window.open(
					`https://pos.snapscan.io/qr/ZtzVHGvO?id=${
						shipment.shipment_id || multipieceShipment.shipmentId
					}&amount=${snapScanTotal}`,
					'_blank',
					'noopener,noreferrer'
				);
			}}
		>
			Pay Now
		</Button>
	);
}
