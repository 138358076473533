import { Alert, Box, CircularProgress, Container, Divider, Grid, Paper, Snackbar, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutShippingForm from './components/CheckoutShippingMethodForm';
import ShippingRateList, { MockShopifyRateOption, rateOptions } from './components/ShippingRateList';
import { useMerchant } from '@context/MerchantContext';
import { SnackAlert } from '@tunl-types/util';
import { axiosConfig } from '@constants/axios';
import { CarrierServiceInput } from '@tunl-types/checkoutCarrierServiceSettings';
import { MerchantRecord } from '@tunl-types/merchant';
import { styleContainer } from '@pages/Merchant/BulkShipments/UploadCSV';

export enum PageMode {
	READ,
	WRITE,
	CONTACT_SUPPORT,
	LOADING
}

export enum ShippingMethod {
	DDP,
	DAP,
	BOTH
}

export interface SelectCheckoutShippingMethodsProps {
	initCheck: boolean;
	setInitCheck: (value: boolean) => void;
}

export default function SelectCheckoutShippingMethods(props: SelectCheckoutShippingMethodsProps) {
	const { initCheck, setInitCheck } = props;
	const { merchantProfile, setMerchantProfile } = useMerchant();
	const memoizedIntegrations = useMemo(() => merchantProfile?.integrations ?? [], [merchantProfile]);
	const [integrations, setIntegrations] = useState(memoizedIntegrations);
	const [upsShippingMethod, setUpsShippingMethod] = useState<ShippingMethod>(ShippingMethod.DDP);
	const [fedexShippingMethod, setFedExShippingMethod] = useState<ShippingMethod>(ShippingMethod.DDP);
	const [upsCarrierSelected, setUpsCarrierSelected] = useState<boolean>(false);
	const [fedexCarrierSelected, setFedExCarrierSelected] = useState<boolean>(false);
	const [tunlCarrierSelected, setTunlCarrierSelected] = useState<boolean>(false);
	const [selectedRateOptions, setSelectedRateOptions] = useState<Array<MockShopifyRateOption>>([]);
	const [merchantRateOptions, setMerchantRateOptions] = useState<Array<MockShopifyRateOption>>([]);
	const [pageMode, setPageMode] = useState<PageMode>(PageMode.READ);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });

	const axiosInstance = axiosConfig();
	const navigate = useNavigate();

	useEffect(() => {
		setIntegrations(merchantProfile?.integrations ?? []);
		const services = merchantProfile?.integrations?.[0]?.settings?.services ?? [];
		initializeShippingMethods(services);
	}, [memoizedIntegrations, memoizedIntegrations.length, merchantProfile]);

	function initializeShippingMethods(services: { name: string; enabled: boolean }[]) {
		let upsMethod: ShippingMethod | null = null;
		let fedexMethod: ShippingMethod | null = null;
		let upsSelected = false;
		let fedexSelected = false;
		let tunlSelected = false;

		services.forEach(service => {
			if (service.enabled) {
				if (service.name === 'ground') {
					tunlSelected = true;
				}
				if (service.name.includes('ups')) {
					upsSelected = true;
					if (service.name.endsWith('ddp')) {
						upsMethod = upsMethod === ShippingMethod.DAP ? ShippingMethod.BOTH : ShippingMethod.DDP;
					} else if (service.name.endsWith('dap')) {
						upsMethod = upsMethod === ShippingMethod.DDP ? ShippingMethod.BOTH : ShippingMethod.DAP;
					}
				}
				if (service.name.includes('fedex')) {
					fedexSelected = true;
					if (service.name.endsWith('ddp')) {
						fedexMethod = fedexMethod === ShippingMethod.DAP ? ShippingMethod.BOTH : ShippingMethod.DDP;
					} else if (service.name.endsWith('dap')) {
						fedexMethod = fedexMethod === ShippingMethod.DDP ? ShippingMethod.BOTH : ShippingMethod.DAP;
					}
				}
			}
		});

		// Fallback for when no method was enabled
		if (!upsMethod) upsMethod = ShippingMethod.DDP; // Default for UPS, can be adjusted
		if (!fedexMethod) fedexMethod = ShippingMethod.DDP; // Default for FedEx, can be adjusted

		setTunlCarrierSelected(tunlSelected);
		setUpsCarrierSelected(upsSelected);
		setFedExCarrierSelected(fedexSelected);
		setUpsShippingMethod(upsMethod);
		setFedExShippingMethod(fedexMethod);
	}

	const onCancelHandler = async () => {
		if (initCheck) {
			setSnackAlert({ type: 'error', message: 'Please one of the services for your Shopify checkout' });
			setOpenSnackBar(true);
			return;
		}
		const services = integrations?.[0]?.settings?.services ?? [];
		initializeShippingMethods(services);
		setPageMode(PageMode.READ);
	};

	const createService = (
		name: string,
		isCarrierSelected: boolean,
		shippingMethod: ShippingMethod,
		methods: ShippingMethod[]
	) => ({
		name,
		enabled: isCarrierSelected && (methods.includes(shippingMethod) || shippingMethod === ShippingMethod.BOTH)
	});

	const onSaveHandler = async () => {
		setPageMode(PageMode.LOADING);
		const requestData: CarrierServiceInput = {
			platform: integrations[0]?.platform,
			identifier: integrations[0]?.identifier,
			services: []
		};
		requestData.services.push(
			{ name: 'ground', enabled: tunlCarrierSelected },
			createService('fedex_international_priority_ddp', fedexCarrierSelected, fedexShippingMethod, [
				ShippingMethod.DDP
			]),
			createService('upssaver_ddp', upsCarrierSelected, upsShippingMethod, [ShippingMethod.DDP]),
			createService('fedex_international_priority_dap', fedexCarrierSelected, fedexShippingMethod, [
				ShippingMethod.DAP
			]),
			createService('upssaver_dap', upsCarrierSelected, upsShippingMethod, [ShippingMethod.DAP])
		);

		try {
			const response = await axiosInstance.put(
				`integrations/platform/${requestData.platform}/carrier-services`,
				requestData
			);
			if (response.status === 200) {
				const responseMerchant: MerchantRecord = response.data.data;
				setMerchantProfile(responseMerchant);
				navigate('/live-rates');
				setPageMode(PageMode.READ);
				setInitCheck(false);
			}
		} catch (error) {
			setPageMode(PageMode.READ);
		}
		setPageMode(PageMode.READ);
	};

	const onEditHandler = async () => {
		setPageMode(PageMode.WRITE);
	};

	const isExpressOptionSelected = (
		option: MockShopifyRateOption,
		carrierSelected: boolean,
		carrier: string,
		shippingMethod: ShippingMethod
	): boolean => {
		if (!carrierSelected) return false;

		if (shippingMethod === ShippingMethod.BOTH) {
			return option.carrier === carrier;
		} else {
			return option.carrier === carrier && option.dutiesIncluded === (shippingMethod !== ShippingMethod.DAP);
		}
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const filterRateOptionsOnSelectedCarriersAndShippingMethod = (): Array<MockShopifyRateOption> => {
		const filteredRateOptions = rateOptions.filter(option => {
			if (isExpressOptionSelected(option, upsCarrierSelected, 'UPS', upsShippingMethod)) {
				return true;
			}

			if (isExpressOptionSelected(option, fedexCarrierSelected, 'FedEx', fedexShippingMethod)) {
				return true;
			}

			if (tunlCarrierSelected) {
				return option.carrier === 'Economy';
			}
			return false;
		});
		return filteredRateOptions;
	};

	useEffect(() => {
		setSelectedRateOptions(filterRateOptionsOnSelectedCarriersAndShippingMethod());
		if (merchantRateOptions.length === 0) {
			setMerchantRateOptions(filterRateOptionsOnSelectedCarriersAndShippingMethod());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upsCarrierSelected, upsShippingMethod, fedexCarrierSelected, fedexShippingMethod, tunlCarrierSelected]);

	return (
		<>
			<Grid item xs={10} textAlign="left">
				<Paper elevation={3}>
					<Grid container direction="column" p={2} spacing={2}>
						<Grid item>
							<Box display="flex" alignItems="center">
								<img
									srcSet={`./img/shopify-logo.png`}
									src={`./img/shopify-logo.png`}
									alt="Shopify Logo"
									loading="lazy"
									style={{ marginLeft: '0px', width: '72px', height: '72px' }}
								/>
								<Typography variant="h5">Connected Shopify</Typography>
							</Box>
							<Divider />
						</Grid>
						{pageMode === PageMode.LOADING && loadingStateView()}

						{(pageMode === PageMode.READ || pageMode === PageMode.WRITE) && (
							<>
								<Grid item container direction={'row'}>
									<Grid item xs={selectedRateOptions.length === 0 ? 12 : 5}>
										<CheckoutShippingForm
											upsShippingMethod={upsShippingMethod}
											setUpsShippingMethod={setUpsShippingMethod}
											fedexShippingMethod={fedexShippingMethod}
											setFedExShippingMethod={setFedExShippingMethod}
											upsCarrierSelected={upsCarrierSelected}
											setUpsCarrierSelected={setUpsCarrierSelected}
											fedexCarrierSelected={fedexCarrierSelected}
											setFedExCarrierSelected={setFedExCarrierSelected}
											tunlCarrierSelected={tunlCarrierSelected}
											setTunlCarrierSelected={setTunlCarrierSelected}
											disabled={pageMode === PageMode.READ && !initCheck ? true : false}
											pageMode={pageMode}
											onCancelHandler={onCancelHandler}
											onSaveHandler={onSaveHandler}
											onEditHandler={onEditHandler}
											initCheck={initCheck}
										/>
									</Grid>

									{selectedRateOptions.length !== 0 && (
										<Grid
											item
											xs={7}
											pl={2}
											justifyContent={'space-between'}
											sx={{
												borderLeft: '1px solid',
												borderColor: 'divider'
											}}
										>
											<Typography mb={1} variant="h6">
												Shopify Checkout Preview
											</Typography>
											<ShippingRateList options={selectedRateOptions} />
										</Grid>
									)}
								</Grid>
							</>
						)}
					</Grid>
				</Paper>
			</Grid>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</>
	);

	function loadingStateView() {
		return (
			<Grid container pl={2}>
				<Grid item xs={12}>
					<Box sx={{ textAlign: 'center' }}>
						<Typography m={2} variant="h6">
							Updating Shopify Carrier Services
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Container disableGutters={false} sx={styleContainer}>
						<CircularProgress />
					</Container>
				</Grid>
			</Grid>
		);
	}
}
