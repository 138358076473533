import { CurrencyExchangeRateResponse } from '@tunl-types/currency';
import { AddressData } from '../types/address';
import { ParcelsWithCustomsData } from '../types/consignment';
import { Pickup } from '../types/pickup';
import { RatesWithSelectedService } from '../types/rates';
import { FormSections, TriggerSections } from './ShipmentContext';

export enum ShipmentActionTypes {
	UPDATE_FORM_SECTION_STATE = 'UPDATE_FORM_SECTION_STATE',
	UPDATE_TRIGGER_STATE = 'UPDATE_TRIGGER_STATE',
	UPDATE_FROM_ADDRESS = 'UPDATE_FROM_ADDRESS',
	UPDATE_TO_ADDRESS = 'UPDATE_TO_ADDRESS',
	UPDATE_RATES = 'UPDATE_RATES',
	UPDATE_PARCELS = 'UPDATE_PARCELS',
	UPDATE_EXCHANGE_RATE = 'UPDATE_EXCHANGE_RATE',
	UPDATE_PROTECTION_VALUE = 'UPDATE_PROTECTION_VALUE',
	UPDATE_PICKUP = 'UPDATE_PICKUP'
}

export type UPDATE_FORM_SECTION_STATE_ACTION = {
	type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE;
	payload: {
		sectionName: keyof FormSections;
		complete: boolean;
	};
};

export type UPDATE_TRIGGER_STATE_ACTION = {
	type: ShipmentActionTypes.UPDATE_TRIGGER_STATE;
	payload: {
		key: keyof TriggerSections;
		value: boolean;
	};
};

export type UPDATE_FROM_ADDRESS_ACTION = {
	type: ShipmentActionTypes.UPDATE_FROM_ADDRESS;
	payload: AddressData;
};

export type UPDATE_TO_ADDRESS_ACTION = {
	type: ShipmentActionTypes.UPDATE_TO_ADDRESS;
	payload: AddressData;
};

export type UPDATE_PARCELS_ACTION = {
	type: ShipmentActionTypes.UPDATE_PARCELS;
	payload: ParcelsWithCustomsData;
};

export type UPDATE_RATES_ACTION = {
	type: ShipmentActionTypes.UPDATE_RATES;
	payload: RatesWithSelectedService;
};

export type UPDATE_EXCHANGE_RATE_ACTION = {
	type: ShipmentActionTypes.UPDATE_EXCHANGE_RATE;
	payload: CurrencyExchangeRateResponse;
};

export type UPDATE_PROTECTION_VALUE_ACTION = {
	type: ShipmentActionTypes.UPDATE_PROTECTION_VALUE;
	payload: number;
};

export type UPDATE_PICKUP_ACTION = {
	type: ShipmentActionTypes.UPDATE_PICKUP;
	payload: Pickup;
};

export type ShipmentActions =
	| UPDATE_FORM_SECTION_STATE_ACTION
	| UPDATE_TRIGGER_STATE_ACTION
	| UPDATE_FROM_ADDRESS_ACTION
	| UPDATE_TO_ADDRESS_ACTION
	| UPDATE_PARCELS_ACTION
	| UPDATE_EXCHANGE_RATE_ACTION
	| UPDATE_PROTECTION_VALUE_ACTION
	| UPDATE_RATES_ACTION
	| UPDATE_PICKUP_ACTION;
