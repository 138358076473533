import {
	Typography,
	Tooltip,
	Button,
	MenuItem,
	FormControl,
	Select,
	SelectChangeEvent,
	CardContent,
	Card,
	CardActions,
	Skeleton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BootstrapInput } from './BootstrapInput';
import { getMaxDateTime, getMinDateTime } from '../utils/pickup';
import { MultiPieceShipment } from '@tunl-types/shipment';
import { formatDateTime, pickupDateFedex, pickupDateUPS } from '@util/date';
import { axiosConfig } from '@constants/axios';
import { TrackingStatus } from '@tunl-types/tracker';
import { pickupTypeMap } from '@util/shipments';
import {
	CHECKOUT,
	CREATE_MULTIPIECE_SHIPMENTS,
	createElementId,
	EXPRESS,
	FORM_CONTROL,
	MENU_ITEM,
	PARCEL_COLLECTION,
	PICK_UP_TIME_SLOT,
	SELECT
} from '@constants/id';

interface props {
	shipment: MultiPieceShipment;
}

export default function PickupDetails({ shipment }: props) {
	const [loading, setLoading] = useState(false);
	const [pickupDetails, setPickup] = useState({
		id: '',
		minDatetime: '',
		maxDatetime: '',
		confirmation: ''
	});
	const [showPickupSelect, setShowPickupSelect] = useState(false);
	const { today, tomorrow, monday } = {
		...(shipment.carrierService === 'FEDEX_INTERNATIONAL_PRIORITY' ? pickupDateFedex() : pickupDateUPS())
	};
	const axiosInstance = axiosConfig();

	const disablePickupButton = () => {
		return (
			shipment?.recordType !== 'express' ||
			![TrackingStatus.PreTransit, TrackingStatus.TBD].includes(shipment.eventStatus as TrackingStatus)
		);
	};

	const getPickup = async () => {
		try {
			const response = await axiosInstance.post('/pickup/getExpressPickup', {
				pickup_id: shipment.pickupId
			});

			const { id, min_datetime, max_datetime, confirmation } = response.data.data;

			setPickup({
				id: id,
				minDatetime: formatDateTime(min_datetime),
				maxDatetime: formatDateTime(max_datetime),
				confirmation: confirmation
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching express pickup:', error);
		}
	};

	useEffect(() => {
		if (shipment.pickupId) {
			setLoading(true);
			getPickup();
		}
	}, [shipment]);

	const { control, watch, setValue, getValues } = useForm<any>({
		mode: 'all',
		defaultValues: {
			pickup_info: {
				slot: 0,
				pickupSelected: 1,
				minDatetime: pickupDetails?.minDatetime,
				maxDatetime: pickupDetails?.maxDatetime,
				isAccountAddress: false
			},
			from_address: {
				addressFromCityLocality: shipment.addressFromCityLocality,
				addressFromCountry: shipment.addressFromCountry,
				addressFromEmail: shipment.addressFromEmail,
				addressFromName: shipment.addressFromName,
				addressFromPhone: shipment.addressFromPhone,
				addressFromStateProvince: shipment.addressFromStateProvince,
				addressFromStreetAddress1: shipment.addressFromStreetAddress1,
				addressFromStreetAddress2: shipment.addressFromStreetAddress2,
				addressFromZipPostal: shipment.addressFromZipPostal
			},
			reference: shipment?.shipmentReference,
			shipmentId: shipment.shipmentId
		}
	});

	const handlePickupChange = async (event: SelectChangeEvent<number>) => {
		const selectedValue = event.target.value;
		if (selectedValue) {
			setValue('timeSlot', Number(event.target.value));
			setLoading(true);
			setShowPickupSelect(false);
			try {
				setValue('pickupSelected', Number(event.target.value));
				const response = await axiosInstance.post('/pickup/createAndBuyPickup', {
					pickup_info: {
						slot: getValues('timeSlot'),
						pickupSelected: 1,
						minDatetime: getMinDateTime(getValues('timeSlot'), today, tomorrow, monday),
						maxDatetime: getMaxDateTime(getValues('timeSlot'), today, tomorrow, monday),
						isAccountAddress: false
					},
					from_address: {
						addressFromCityLocality: shipment.addressFromCityLocality,
						addressFromCountry: shipment.addressFromCountry,
						addressFromEmail: shipment.addressFromEmail,
						addressFromName: shipment.addressFromName,
						addressFromPhone: shipment.addressFromPhone,
						addressFromStateProvince: shipment.addressFromStateProvince,
						addressFromStreetAddress1: shipment.addressFromStreetAddress1,
						addressFromStreetAddress2: shipment.addressFromStreetAddress2,
						addressFromZipPostal: shipment.addressFromZipPostal
					},
					reference: shipment?.shipmentReference,
					shipmentId: shipment.shipmentId
				});
				setPickup({
					id: response.data.data.id,
					minDatetime: formatDateTime(response.data.data.min_datetime),
					maxDatetime: formatDateTime(response.data.data.max_datetime),
					confirmation: response.data.data.confirmation
				});
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		}
	};

	function getCollectionText() {
		if (Object.keys(pickupTypeMap).includes(shipment.pickupType)) {
			return pickupTypeMap[shipment.pickupType];
		}
		return 'Collection not required';
	}

	return (
		<>
			{loading ? (
				<Card raised={true} elevation={3} sx={{ minHeight: '100%' }}>
					<CardContent>
						<Skeleton width="100%" variant="text" />
						<Skeleton width="100%" variant="text" />
						<Skeleton width="100%" variant="text" />
						<Skeleton width="100%" variant="text" />
						<Skeleton width="100%" variant="text" />
					</CardContent>
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<Skeleton width={50} height={20} variant="rounded" />
					</CardActions>
				</Card>
			) : (
				<Card sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }} raised={true} elevation={3}>
					<CardContent>
						<Typography gutterBottom variant="subtitle1" fontWeight={'bold'}>
							Collection Details:
						</Typography>
						{pickupDetails.id ? (
							<>
								<Typography variant="subtitle2" fontWeight={'bold'}>
									Earliest collection:{' '}
								</Typography>
								<Typography variant="subtitle2">{pickupDetails?.minDatetime}</Typography>

								<Typography variant="subtitle2" fontWeight={'bold'}>
									Latest collection:{' '}
								</Typography>
								<Typography variant="subtitle2">{pickupDetails?.maxDatetime}</Typography>

								<Typography variant="subtitle2" fontWeight={'bold'}>
									Confirmation Code:{' '}
								</Typography>
								<Typography variant="subtitle2">{pickupDetails?.confirmation}</Typography>
							</>
						) : (
							<Grid
								container
								direction={'row'}
								spacing={1}
								alignItems={'center'}
								justifyContent={'flex-start'}
								sx={{ p: 2 }}
							>
								<Typography variant="subtitle2">{getCollectionText()}</Typography>
							</Grid>
						)}
					</CardContent>
					{shipment && (
						<CardActions sx={{ mt: 'auto', justifyContent: 'center' }}>
							{!showPickupSelect ? (
								<Tooltip title={'Pickups can only be changed for shipments pre-transit'}>
									<span>
										<Button
											disabled={disablePickupButton()}
											color="primary"
											variant="contained"
											size="small"
											fullWidth
											onClick={() => {
												setShowPickupSelect(true);
											}}
										>
											{shipment.pickupType == 'PICKUP_REQUIRED'
												? 'Reschedule Collection'
												: 'Schedule Collection'}
										</Button>
									</span>
								</Tooltip>
							) : (
								<>
									<Controller
										control={control}
										name="timeSlot"
										render={({ field: { value } }) => (
											<FormControl
												id={createElementId([
													CREATE_MULTIPIECE_SHIPMENTS,
													CHECKOUT,
													EXPRESS,
													PARCEL_COLLECTION,
													FORM_CONTROL,
													PICK_UP_TIME_SLOT
												])}
												variant={'standard'}
												error
												fullWidth
											>
												<Select
													id={createElementId([
														CREATE_MULTIPIECE_SHIPMENTS,
														CHECKOUT,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT
													])}
													name="timeSlot"
													value={0}
													onChange={event =>
														handlePickupChange(event as SelectChangeEvent<number>)
													}
													input={
														<BootstrapInput
															error={
																watch('pickupSelected') === 1 && watch('timeSlot') === 0
															}
														/>
													}
													fullWidth
												>
													<MenuItem
														id={createElementId([
															CREATE_MULTIPIECE_SHIPMENTS,
															CHECKOUT,
															EXPRESS,
															PARCEL_COLLECTION,
															FORM_CONTROL,
															SELECT,
															PICK_UP_TIME_SLOT,
															MENU_ITEM,
															'default'
														])}
														disabled
														value={0}
													>
														<em>Please Select Timeslot</em>
													</MenuItem>
													{today?.morning && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'today_morning'
															])}
															value={1}
														>
															Today (Morning)
														</MenuItem>
													)}
													{today?.afternoon && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'today_afternoon'
															])}
															value={2}
														>
															Today (Afternoon)
														</MenuItem>
													)}
													{tomorrow?.morning && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'tomorrow_morning'
															])}
															value={3}
														>
															Tomorrow (Morning)
														</MenuItem>
													)}
													{tomorrow?.afternoon && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'tomorrow_morning'
															])}
															value={4}
														>
															Tomorrow (Afternoon)
														</MenuItem>
													)}
													{monday?.morning && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'monday_morning'
															])}
															value={5}
														>
															Monday (Morning)
														</MenuItem>
													)}
													{monday?.afternoon && (
														<MenuItem
															id={createElementId([
																CREATE_MULTIPIECE_SHIPMENTS,
																CHECKOUT,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'monday_afternoon'
															])}
															value={6}
														>
															Monday (Afternoon)
														</MenuItem>
													)}
												</Select>
											</FormControl>
										)}
									/>
								</>
							)}
						</CardActions>
					)}
				</Card>
			)}
		</>
	);
}
