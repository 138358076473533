import { Download } from '@mui/icons-material';
import { usePDF } from '@react-pdf/renderer';
import GenerateCommercialInvoice from './GenerateCommercialInvoice';
import { Shipment } from '@tunl-types/shipment';
import { useMerchant } from '@context/MerchantContext';
import { setShipment } from '@stores/shipment';
import { setMerchant } from '@stores/merchant';
import RoundedButton from '@components/Common/RoundedButton';
export interface CommercialInvoiceButtonProps {
	shipment: Shipment;
}

export default function CommercialInvoiceButton(props: CommercialInvoiceButtonProps) {
	const { shipment } = props;
	const { merchantProfile } = useMerchant();
	const [invoice, setInvoice] = usePDF({
		document:
			shipment && merchantProfile ? (
				<GenerateCommercialInvoice shipment={shipment} merchant={merchantProfile} />
			) : undefined
	});

	const generatePDF = () => {
		// Update the data dynamically before generating the PDF
		const dynamicShipment = {
			/* fetch or assign shipment data */
		};
		const dynamicMerchant = {
			/* fetch or assign merchant data */
		};

		setShipment(dynamicShipment);
		setMerchant(dynamicMerchant);

		// Trigger the PDF generation
		setInvoice(<GenerateCommercialInvoice shipment={shipment} merchant={merchantProfile} />);
	};

	const downloadPDF = () => {
		if (invoice && invoice.url) {
			window.open(invoice.url, '_blank');
		}
	};

	return (
		<RoundedButton
			color="primary"
			disabled={false}
			handleOnClickEvent={() => {
				generatePDF();
				downloadPDF();
			}}
			icon={<Download />}
			label="Invoice"
		></RoundedButton>
	);
}
