import AuthenticationContainer from '@components/Layout/AuthenticationContainer';
import MerchantAddress from '@components/Merchant/MerchantAddress';
import { axiosConfig } from '@constants/axios';
import {
	ALERT,
	BUTTON,
	CREATE_MERCHANT,
	createElementId,
	DROPDOWN,
	FORM_CONTROL,
	FORM_CONTROL_LABEL,
	HEADING,
	INFO,
	LABEL,
	LINK,
	MENU_ITEM,
	MERCHANT_PROFILE,
	SELECT,
	SWITCH,
	TEXTFIELD,
	TITLE
} from '@constants/id';
import { useMerchant } from '@context/MerchantContext';
import {
	Alert,
	AlertTitle,
	Button,
	CircularProgress,
	FormControlLabel,
	Grid2 as Grid,
	MenuItem,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import { MerchantRecord } from '@tunl-types/merchant';
import { createUserProfile } from '@util/user';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { ECOMMERCE_PLATFORMS, SHOPIFY_PLANS } from './utils/merchant';

export default function CreateMerchant(props: any) {
	const { setMerchantProfile, setAvailableMerchants, merchantProfile, user, setUserProfile, setAppState } =
		useMerchant();
	const navigate = useNavigate();
	const {
		register,
		setValue,
		watch,
		trigger,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'all',
		defaultValues: {
			store_domain: '',
			user_id: user?.username,
			merchant_id: v4(),
			merchant_name: '',
			merchant_email: '',
			merchant_phone: '',
			merchant_vat_number: '',
			merchant_personal_id: '',
			merchant_exporters_code: '',
			merchant_heard_about_from: '',
			merchant_heard_about_from_other: '',
			merchant_shipments_per_month: '',
			ecommerce_platform: '',
			other_ecommerce_platform: '',
			ecommerce_plan: ''
		}
	});

	const [loading, setLoading] = useState(false);
	const [hasVATCode, setHasVATCode] = useState(true);
	const [hasExportersCode, setHasExportersCode] = useState(true);
	const axiosInstance = axiosConfig();
	const posthog = usePostHog();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const onboardNewUser = async () => {
			await createUserProfile(user);
			setUserProfile(JSON.parse(sessionStorage.getItem('user') as string));
		};

		if (searchParams.get('create_profile') === 'true') {
			// add a fallback for creating a user
			onboardNewUser();
		} else {
			setUserProfile(JSON.parse(sessionStorage.getItem('user') as string));
		}
	}, [user]);

	const handleFormSubmit = async (event: any) => {
		setLoading(true);
		try {
			const createMerchantPayload = {
				...event,
				ecommerce_platform: {
					name:
						event.ecommerce_platform !== 'Other'
							? event.ecommerce_platform
							: event.other_ecommerce_platform,
					plan: event.ecommerce_platform === 'Shopify' ? event.ecommerce_plan : ''
				}
			};

			let response = await axiosInstance.post('/merchant/createMerchant', [createMerchantPayload]);
			const merchant: MerchantRecord = response.data.merchantRecords[0];
			sessionStorage.setItem('activeUserMerchant', JSON.stringify(merchant));
			sessionStorage.setItem('userMerchants', JSON.stringify(response.data.merchantRecords));
			sessionStorage.setItem('merchant', JSON.stringify(merchant));

			posthog.capture('merchant_created', {
				name: merchant.merchant_name,
				store_domain: merchant.store_domain,
				source: merchant.merchant_heard_about_from,
				address_city: merchant.merchant_city_locality,
				address_province: merchant.merchant_state_province,
				address_street_1: merchant.merchant_street_1,
				address_street_2: merchant.merchant_street_2,
				address_postal: merchant.merchant_zip_postal,
				email: merchant.merchant_email,
				phone: merchant.merchant_phone,
				$set: { merchant_name: merchant.merchant_name }
			});
			try {
				await axiosInstance.post(`/user/createCRMUser?userId=${user?.username}`, event);
			} catch (err) {
				console.log(err);
			}

			//add user to crisp
			setMerchantProfile(merchant);
			setAvailableMerchants([
				{
					date_created: '',
					merchant_user_email: user?.attributes?.email || '',
					merchant_user_status: 'primary',
					partitionKey: merchant.partitionKey,
					merchant_id: merchant.merchant_id,
					user_id: user?.username || '',
					merchant_name: merchant.merchant_name,
					record_type: 'User Merchant'
				}
			]);

			setAppState('ready');
			navigate('/');
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const heardAboutFrom = watch('merchant_heard_about_from');

	return (
		<>
			{merchantProfile._id ? (
				<Navigate to="/" />
			) : (
				<AuthenticationContainer text="Sign up for free and book your first parcel in under 2 minutes.">
					<form
						onSubmit={handleSubmit(handleFormSubmit)}
						style={{
							width: '100%',
							height: 'auto',
							maxHeight: 'auto',
							maxWidth: 'auto',
							overflow: 'scroll'
						}}
					>
						<Grid container direction="row" lineHeight={3} p={3} spacing={1}>
							<Grid size={12}>
								<Typography
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL_LABEL,
										HEADING,
										'company_information'
									])}
									variant="h4"
									textAlign="center"
									justifyContent="center"
								>
									Company Information
								</Typography>
								<Typography
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL,
										LABEL,
										'company_information_description'
									])}
									fontWeight="bold"
									textAlign="center"
								>
									Provide your company information to create your free account and get shipping in
									seconds.
								</Typography>
							</Grid>
							<Grid size={12}>
								<TextField
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'company_name'])}
									required
									label="Company Name"
									fullWidth
									{...register('merchant_name', {})}
									error={!!errors?.merchant_name}
									helperText={
										errors?.merchant_name
											? (errors.merchant_name.message as string)
											: "This will appear on shipping documents. Don't worry, you can change it later"
									}
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<TextField
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'merchant_email'])}
									required
									label="Accounts Email"
									{...register('merchant_email', {
										pattern: {
											value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
											message: 'Invalid email address'
										}
									})}
									error={!!errors?.merchant_email}
									helperText={
										errors?.merchant_email
											? (errors.merchant_email.message as string)
											: 'We will send invoices/statements to this email address'
									}
									fullWidth
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} mb={2}>
								<TextField
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'merchant_phone'])}
									required
									label="Phone"
									{...register('merchant_phone', {
										pattern: {
											value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
											message: 'Invalid Phone number'
										}
									})}
									helperText={
										errors?.merchant_phone
											? (errors.merchant_phone.message as string)
											: 'We will call this number if we need to confirm any shipment details'
									}
									fullWidth
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<FormControlLabel
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL,
										FORM_CONTROL_LABEL,
										'exporters_code'
									])}
									label="Do you have an Exporters code?"
									control={
										<Switch
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												SWITCH,
												'exporters_code'
											])}
											checked={hasExportersCode}
											onChange={e => setHasExportersCode(e.target.checked)}
										/>
									}
								/>
								{hasExportersCode && (
									<Grid>
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												TEXTFIELD,
												'exporters_code'
											])}
											label="Exporter's Code"
											required={hasExportersCode}
											{...register('merchant_exporters_code', {
												pattern: {
													value: /^[a-zA-Z0-9]{8,10}$/im,
													message: 'Invalid Exporters Code'
												}
											})}
											error={!!errors?.merchant_exporters_code}
											fullWidth
										/>
									</Grid>
								)}
								{!hasExportersCode && (
									<Grid>
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												TEXTFIELD,
												'id_number'
											])}
											required={!hasExportersCode}
											label="ID Number"
											fullWidth
											{...register('merchant_personal_id', {
												pattern: {
													value: /^[0-9]{13}$/im,
													message: 'Invalid Id Number'
												}
											})}
											error={!!errors?.merchant_personal_id}
											helperText={
												errors?.merchant_personal_id
													? (errors.merchant_personal_id.message as string)
													: null
											}
										/>
									</Grid>
								)}
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<FormControlLabel
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, FORM_CONTROL_LABEL, 'vat'])}
									label="Are you registered for VAT?"
									control={
										<Switch
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												FORM_CONTROL_LABEL,
												SWITCH,
												'vat'
											])}
											checked={hasVATCode}
											onChange={e => setHasVATCode(e.target.checked)}
										/>
									}
								/>
								{hasVATCode && (
									<Grid>
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												TEXTFIELD,
												'vat_number'
											])}
											label="Vat Number"
											fullWidth
											{...register('merchant_vat_number', {})}
										/>
									</Grid>
								)}
							</Grid>
							<Grid size={12}>
								<Grid container direction="column" spacing={0}>
									<Typography
										id={createElementId([CREATE_MERCHANT, FORM_CONTROL, LABEL, 'physical_address'])}
										fontWeight="bold"
										textAlign="center"
										mb={2}
									>
										Provide your physical address. This is required for getting shipping rates and
										scheduling pickups.
									</Typography>
									<MerchantAddress
										errors={errors}
										register={register}
										setValue={setValue}
										trigger={trigger}
										watch={watch}
									/>
								</Grid>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} mb={2}>
								<Grid container direction="column" spacing={0}>
									<Typography
										id={createElementId([
											CREATE_MERCHANT,
											FORM_CONTROL,
											LABEL,
											'shipment_information'
										])}
										fontWeight="bold"
										textAlign="center"
									>
										How many shipments do you export monthly?
									</Typography>
									<TextField
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											'shipments_per_month'
										])}
										select
										label="Number of shipments"
										fullWidth
										defaultValue={'None'}
										helperText="Your response won't impact our rates, it simply helps us understand you better."
										value={watch('merchant_shipments_per_month')}
										{...register('merchant_shipments_per_month', {})}
									>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'none'
											])}
											value="None"
										>
											I am currently not shipping internationally
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'1-5'
											])}
											value="1 to 5"
										>
											1 - 5 shipments per month
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'5-10'
											])}
											value="5 to 10"
										>
											5 - 10 shipments per month
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'10-20'
											])}
											value="10 to 20"
										>
											10 - 20 shipments per month
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'20-50'
											])}
											value="20 to 50"
										>
											20 - 50 shipments per month
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'50+'
											])}
											value="50+"
										>
											50+ shipments per month
										</MenuItem>
									</TextField>
								</Grid>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} mb={2}>
								<Typography
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, LABEL, 'store_domain'])}
									fontWeight="bold"
									textAlign="center"
								>
									Store Domain
								</Typography>
								<TextField
									id={createElementId([CREATE_MERCHANT, TEXTFIELD, SELECT, DROPDOWN, 'store_domain'])}
									label="Store Domain"
									fullWidth
									{...register('store_domain', {})}
									error={!!errors?.store_domain}
									helperText={
										errors?.store_domain
											? (errors.store_domain.message as string)
											: 'Please provide the domain of your store. This will help merchants find your store.'
									}
								/>
							</Grid>
							<Grid container direction="row" size={12}>
								<Grid size={12}>
									<Typography
										id={createElementId([
											CREATE_MERCHANT,
											FORM_CONTROL,
											LABEL,
											'e-coomerce-plaform-information'
										])}
										fontWeight="bold"
										textAlign="center"
									>
										Which E-commerce platform are you using for your store?
									</Typography>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
									<TextField
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											'e_commerce_platform'
										])}
										select
										label="E-Commerce platform"
										fullWidth
										defaultValue={'Shopify'}
										helperText="Select your online store platform from the list or select `Other`."
										value={watch('ecommerce_platform')}
										{...register('ecommerce_platform')}
									>
										{ECOMMERCE_PLATFORMS.map(ecommerce_platform => (
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													`${ecommerce_platform.toLocaleLowerCase()}`
												])}
												value={ecommerce_platform}
											>
												{ecommerce_platform}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
									{watch('ecommerce_platform') == 'Other' ? (
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												'other_ecommerce_platform'
											])}
											label="E-commerce platform name"
											fullWidth
											{...register('other_ecommerce_platform')}
											error={!!errors?.other_ecommerce_platform}
											helperText={
												errors?.other_ecommerce_platform
													? (errors.other_ecommerce_platform.message as string)
													: 'Enter e-commerce platform name.'
											}
										/>
									) : (
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												'e_commerce_platform_plan'
											])}
											select
											disabled={watch('ecommerce_platform') != 'Shopify'}
											label="Platform plan"
											fullWidth
											defaultValue={'Shopify'}
											helperText="Select e-commerce platform plan from the list."
											value={watch('ecommerce_plan')}
											{...register('ecommerce_plan')}
										>
											{SHOPIFY_PLANS.map(plan => (
												<MenuItem
													id={createElementId([
														MERCHANT_PROFILE,
														TEXTFIELD,
														SELECT,
														DROPDOWN,
														MENU_ITEM,
														`${plan.toLocaleLowerCase()}`
													])}
													value={plan}
												>
													{plan}
												</MenuItem>
											))}
										</TextField>
									)}
								</Grid>
							</Grid>
							<Grid size={12}>
								<Grid container direction="column" spacing={1}>
									<Grid>
										<Typography fontWeight="bold" textAlign="center">
											How did you hear about TUNL? This enables us to help more merchants like you
										</Typography>
									</Grid>
									<Grid>
										<TextField
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												'heard_about_from'
											])}
											select
											label="Please choose one..."
											value={watch('merchant_heard_about_from')}
											{...register('merchant_heard_about_from', {})}
											fullWidth
										>
											<MenuItem
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													'customer_referral'
												])}
												value="customer_referral"
											>
												Referral from existing customer
											</MenuItem>
											<MenuItem
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													'email_marketing'
												])}
												value="email_marketing"
											>
												Email Marketing
											</MenuItem>
											<MenuItem
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													'social_media'
												])}
												value="social_media"
											>
												Social Media (LinkedIn, Instagram, etc.)
											</MenuItem>
											<MenuItem
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													'search_engine'
												])}
												value="search_engine"
											>
												Search Engine (Google, Yahoo, etc.)
											</MenuItem>
											<MenuItem
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													SELECT,
													DROPDOWN,
													MENU_ITEM,
													'other'
												])}
												value="other"
											>
												Other
											</MenuItem>
										</TextField>
									</Grid>
									{(heardAboutFrom === 'other' || heardAboutFrom === 'customer_referral') && (
										<Grid>
											<TextField
												id={createElementId([
													CREATE_MERCHANT,
													TEXTFIELD,
													'heard_about_from_other'
												])}
												label={
													heardAboutFrom === 'other'
														? 'Tell us how you heard about TUNL'
														: 'Let us know who referred you'
												}
												{...register('merchant_heard_about_from_other', {})}
												fullWidth
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid size={12}>
								<Typography fontWeight="bold" textAlign="center">
									<span>
										By creating an account you agree to our{' '}
										<a
											id={createElementId([CREATE_MERCHANT, LINK, 'terms_of_service'])}
											href="https://www.tunl.to/terms"
											rel="noreferrer"
											target="_blank"
										>
											Terms of Service
										</a>
									</span>
								</Typography>
							</Grid>
							<Grid mt={1} mb={1}>
								<Alert
									id={createElementId([CREATE_MERCHANT, ALERT, INFO, 'eligibility'])}
									severity="info"
								>
									<AlertTitle
										id={createElementId([CREATE_MERCHANT, ALERT, INFO, TITLE, 'eligibility_title'])}
									>
										Eligibility Criteria
									</AlertTitle>
									We exclusively assist South African businesses, artists, makers, and manufacturers
									selling products internationally. We do not support food manufacturers, individuals
									seeking to send gifts, or primarily importers. For assistance, please reach out to{' '}
									<a
										id={createElementId([CREATE_MERCHANT, LINK, 'support'])}
										href="mailto:support@tunl.to"
									>
										support@tunl.to
									</a>
									.
								</Alert>
							</Grid>
							<Grid
								style={{
									paddingTop: '0px'
								}}
							>
								<Button
									id={createElementId([CREATE_MERCHANT, BUTTON, 'create_account'])}
									fullWidth
									type="submit"
									variant="contained"
									disabled={loading}
								>
									{loading && (
										<CircularProgress
											size="1rem"
											color="inherit"
											sx={{
												marginRight: '10px'
											}}
										/>
									)}
									Create Account
								</Button>
							</Grid>
						</Grid>
					</form>
				</AuthenticationContainer>
			)}
		</>
	);
}
