import { MerchantDashboardProvider } from '@pages/Merchant/MerchantDashboard/context/MerchantDashboardContext';
import MerchantDashboard from '@pages/Merchant/MerchantDashboard/MerchantDashboard';

function Home(props: any) {
	return (
		<MerchantDashboardProvider>
			<MerchantDashboard />
		</MerchantDashboardProvider>
	);
}

export default Home;
