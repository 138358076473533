import { axiosConfig } from '@constants/axios';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import { MerchantRecord } from '@tunl-types/merchant';
import { MockShipment } from '@tunl-types/mockShipments';
import { PickUpData } from '@tunl-types/shipment';
import { pickupDateFedex, pickupDateUPS } from '@util/date';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BulkShipmentPageState } from './BulkShipments';
import BulkShippingCost from './components/BulkShippingCost';

interface BulkBuyLabelProps {
	selectedMockShipmentListTemp: Array<MockShipment> | null;
	setSelectedMockShipmentListTemp: (value: any | null) => void;
	setStepper: (step: BulkShipmentPageState) => void;
}

export default function BulkBuyLabel(props: BulkBuyLabelProps) {
	const navigate = useNavigate();
	const { setStepper, selectedMockShipmentListTemp, setSelectedMockShipmentListTemp } = props;
	const axiosInstance = axiosConfig();
	const merchant: MerchantRecord = JSON.parse(sessionStorage.getItem('merchant') as string);
	const [deliveryConfirmation, setDeliveryConfirmation] = useState(merchant.merchant_settings.delivery_confirmation);
	const [inProgress, setInProgress] = useState<boolean | null>(null);
	const [pickUp, setPickUp] = useState(1);
	const [timeSlot, setTimeSlot] = useState(0);
	const [hasExpressShipments, setHasExpressShipments] = useState<boolean>(false);
	//console.log(selectedMockShipmentListTemp);

	const onCloseClickHandler = () => {
		setStepper(BulkShipmentPageState.ViewOrders);
		setSelectedMockShipmentListTemp([]);
	};

	const handleClick = async () => {
		try {
			setInProgress(true);
			const batchShipmentListData = setUpBatchShipmentData();
			const response = await axiosInstance.post('/shipment/createBatchShipments', batchShipmentListData);
			//TODO: set notification
			setInProgress(false);
		} catch (error) {
			console.log(error);
			//TODO: set notification
			setInProgress(false);
		}
	};

	const setUpBatchShipmentData = () => {
		// Map through the selectedMockShipmentListTemp to create batch shipment data

		const batchShipmentListData = selectedMockShipmentListTemp!.map((data, index) =>
			createBatchShipmentData(data, index)
		);

		// Filter shipments by service type
		const fedexFilteredShipmentList = filterShipmentsByService(
			batchShipmentListData,
			'FEDEX_INTERNATIONAL_PRIORITY'
		);
		const upsFilteredShipmentList = filterShipmentsByService(batchShipmentListData, 'UPSSaver');
		const tunlFilteredShipmentList = filterShipmentsByService(batchShipmentListData, 'Ground');

		// Create batch data for different services only if the respective list has items
		const batchData = [
			fedexFilteredShipmentList.length > 0 &&
				createBatchData('FEDEX_INTERNATIONAL_PRIORITY', fedexFilteredShipmentList, 'express'),
			upsFilteredShipmentList.length > 0 && createBatchData('UPSSaver', upsFilteredShipmentList, 'express'),
			tunlFilteredShipmentList.length > 0 && createBatchData('Ground', tunlFilteredShipmentList, 'economy')
		].filter(Boolean); // Filter out `false` values caused by empty lists
		return batchData;
	};

	const setCarrierBatchPickUp = (
		service: string,
		pickUpSelected: boolean,
		selectedTimeSlot: number
	): PickUpData | null => {
		if (pickUpSelected === false || service === 'Ground') return null;
		const { today, tomorrow, monday } =
			service === 'FEDEX_INTERNATIONAL_PRIORITY' ? pickupDateFedex() : pickupDateUPS();
		const minDateTime =
			selectedTimeSlot === 1
				? today.morning.minTime
				: selectedTimeSlot === 2
				? today.afternoon.minTime
				: selectedTimeSlot === 3
				? tomorrow.morning.minTime
				: selectedTimeSlot === 4
				? tomorrow.afternoon.minTime
				: selectedTimeSlot === 5
				? monday.morning.minTime
				: selectedTimeSlot === 6
				? monday.afternoon.minTime
				: 0;
		const maxDateTime =
			selectedTimeSlot === 1
				? today.morning.maxTime
				: selectedTimeSlot === 2
				? today.afternoon.maxTime
				: selectedTimeSlot === 3
				? tomorrow.morning.maxTime
				: selectedTimeSlot === 4
				? tomorrow.afternoon.maxTime
				: selectedTimeSlot === 5
				? monday.morning.maxTime
				: selectedTimeSlot === 6
				? monday.afternoon.maxTime
				: 0;
		console.log({
			slot: selectedTimeSlot,
			pickup_selected: pickUpSelected ? 1 : 0,
			reference: '',
			min_datetime: minDateTime,
			max_datetime: maxDateTime,
			is_account_address: false
		});

		return {
			slot: selectedTimeSlot,
			pickup_selected: pickUpSelected ? 1 : 0,
			reference: '',
			min_datetime: minDateTime,
			max_datetime: maxDateTime,
			is_account_address: false
		} as PickUpData;
	};

	// Function to create batch shipment data for each item
	const createBatchShipmentData = (data: any, index: number) => {
		const { shipment_data, selected_rate, service_type, landed_cost, express_rate } = data;
		const { customs_info } = shipment_data;

		return {
			...data.batch_shipment,
			ddp: service_type === 'DDP',
			to_return: shipment_data.to_return,
			delivery_confirmation: deliveryConfirmation,
			shipment_type: selected_rate.service === 'Ground' ? 'economy' : 'express',
			insurance: shipment_data.insurance,
			invoice: shipment_data.order_number,
			product_reference: shipment_data.reference,
			reference: `${shipment_data.order_number}_${index}_${Date.now()}`,
			customs_incoterm: service_type,
			customs_info: {
				customs_certify: true,
				customs_signer: '',
				contents_type: shipment_data.content_type?.toLowerCase(),
				contents_explanation: '',
				restriction_type: 'none',
				restriction_comments: '',
				eel_pfc: '',
				customs_items: customs_info.customs_items
			},
			landed_cost,
			express_rates: express_rate,
			selected_rate
		};
	};

	// Function to filter shipments by service type

	const filterShipmentsByService = (shipmentList: any[], service: string) =>
		shipmentList.filter((item: any) => item.service === service);

	// Function to create batch data object for a specific service
	const createBatchData = (service: string, shipments: any[], shipment_type: string) => ({
		service,
		shipments,
		merchant_id: merchant.merchant_id,
		partitionKey: merchant.partitionKey,
		shipment_type,
		pick_up: setCarrierBatchPickUp(service, pickUp === 1 ? true : false, timeSlot)
	});

	return (
		<>
			{inProgress === null
				? initBulkBuyLabelView()
				: inProgress === true
				? loadingBulkBuyLabelView()
				: completeSuccessBulkBuyProcessingView()}
		</>
	);

	function loadingBulkBuyLabelView() {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
				<Paper elevation={4}>
					<Container>
						<Grid container>
							<Grid item xs={12}>
								<Box sx={{ textAlign: 'center' }}>
									<Typography m={2} variant="h4">
										Processing Batched Shipments{' '}
									</Typography>
									<Typography>
										This should take a few minutes. Please do not navigate away from this page
									</Typography>
								</Box>
							</Grid>
							{centeredLoader()}
						</Grid>
					</Container>
				</Paper>
			</Box>
		);
	}

	function completeSuccessBulkBuyProcessingView() {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
				<Paper elevation={4}>
					<Container>
						<Grid container>
							<Grid item xs={12}>
								<Box sx={{ textAlign: 'center' }}>
									<Typography m={2} variant="h4">
										Batch Shipments Successfully Created
									</Typography>
									<Typography>
										Please note that additional work is being done in the background to complete the
										purchasing of the shipments
									</Typography>
									<Typography>This will take a few minutes to complete.</Typography>
									<Typography>
										An email will be sent on completion. It will contain a link to download all
										labels.
									</Typography>
									<Typography>You may click Done. This will take you to the dashboard</Typography>
									<Typography>Shipments will reflect on the dashboard in a few minutes.</Typography>
								</Box>
							</Grid>
							{centeredLoader()}
							<Grid
								item
								container
								md={12}
								direction={'row'}
								alignItems={'center'}
								justifyContent="center"
							>
								<Grid item>
									<Box minWidth={100} mb={2}>
										{' '}
										{/* Set a minWidth that matches the longest button */}
										<Button variant="contained" onClick={() => navigate('/')} fullWidth>
											Done
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Paper>
			</Box>
		);
	}

	function initBulkBuyLabelView() {
		return (
			<Box p={2} sx={{ minHeight: '80vh' }}>
				<Paper elevation={4}>
					<Grid container p={2} spacing={2}>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={6}>
									<Box display="flex" alignItems="center" justifyContent="flex-start" height="100%">
										<Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black' }}>
											Buy {selectedMockShipmentListTemp?.length} Labels
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" alignItems="center" justifyContent="flex-end">
										<IconButton aria-label="add-big-box" onClick={onCloseClickHandler} size="large">
											<CloseIcon fontSize="large" sx={{ color: 'black' }} />
										</IconButton>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={12}>
							<BulkShippingCost
								selectedMockShipmentListTemp={selectedMockShipmentListTemp}
								deliveryConfirmation={deliveryConfirmation}
								pickUp={pickUp}
								setPickUp={setPickUp}
								timeSlot={timeSlot}
								setTimeSlot={setTimeSlot}
								setHasExpressShipments={setHasExpressShipments}
							/>
						</Grid>
						<Grid
							item
							container
							md={12}
							direction={'row'}
							alignItems={'center'}
							justifyContent="center"
							spacing={2}
						>
							<Grid item>
								<Box minWidth={100}>
									{' '}
									{/* Set a minWidth that matches the longest button */}
									<Button variant="contained" onClick={onCloseClickHandler} fullWidth>
										Back
									</Button>
								</Box>
							</Grid>
							<Grid item>
								<Box minWidth={100}>
									{' '}
									{/* Use the same minWidth for both buttons */}
									<Button
										variant="contained"
										onClick={handleClick}
										fullWidth
										disabled={hasExpressShipments && pickUp === 1 && timeSlot === 0}
									>
										Buy Labels
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Box>
		);
	}

	function centeredLoader() {
		return (
			<Grid item xs={12} my={2}>
				<Container
					disableGutters
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '20vh'
					}}
				>
					<Box
						sx={{
							bgcolor: '#f0f0f0',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%'
						}}
					>
						<CircularProgress />
					</Box>
				</Container>
			</Grid>
		);
	}
}
